export const managerExportData = [
    ["no", "Manager Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["mobile", "Mobile Number"],
    ["isBlockedD", "Block Manager"],

];

export const bouncerExportData = [
    ["no", "Bouncer Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["mobile", "Mobile Number"],
    ["isBlockedD", "Block Bouncer"],

];

export const valetExportData = [
    ["no", "Valet Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["mobile", "Mobile Number"],
    ["isBlockedD", "Block Valet"],

];

export const managerChatHistoryExportData = [
    ["no", "Chat Id"],
    ["image", "Image"],
    ["name", "Name"],
    ["type", "Type"],
    ["date", "Date & Time"],

];

export const todayEventExportData = [
    ["no", "Event Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["ageRestriction", "Age Limit"],
    ["startTime", "Start Time"],
    ["endTime", "End Time"],
    ["artistTags", "Artist"],

];
export const myEventExportData = [
    ["no", "Event Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["ageRestriction", "Age Limit"],
    ["startDateWithTime", "Start Date & Time"],
    ["endDateWithTime", "End Date & Time"],
    ["artistTags", "Artist"],

];
export const requestExportData = [
    ["no", "Request Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["userName", "User Name"],
    ["ageRestriction", "Age Limit"],
    ["date", "Date"],
    ["time", "Time"],
    ["artistTags", "Artist"],

];
export const eventExportData = [
    ["no", "Event Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["userName", "User Name"],
    ["ageRestriction", "Age Limit"],
    ["date", "Date"],
    ["time", "Time"],
    ["fees", "Fees"],
    ["revenue", "Revenue"],

];
export const rejectedEventExportData = [
    ["no", "Event Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["userName", "User Name"],
    ["ageRestriction", "Age Limit"],
    ["date", "Date"],
    ["time", "Time"],

];
export const approvedEventExportData = [
    ["no", "Event Id"],
    ["image", "Image"],
    ["name", "Event Name"],
    ["userName", "User Name"],
    ["ageRestriction", "Age Limit"],
    ["date", "Date"],
    ["time", "Time"],
    ["fees", "Fees"],
    ["description", "Details"],

];
export const visitsExportData = [
    ["no", "Visist Id"],
    ["image", "Image"],
    ["userName", "User Name"],
    ["ageRestriction", "Age Limit"],
    ["dateTime", "Date & Time"],
    ["likeCount", "Likes"],
    ["commentCount", "Comment"],
    ["caption", "Caption"],

];

export const foodExportData = [
    ["no", "Menu Id"],
    ["image", "Image"],
    ["name", "Food Name"],
    ["priceD", "Price"],
    ["description", "Description"],

];

export const bannerExportData = [
    ["no", "Banner Id"],
    ["image", "Banner"],
    ["link", "Navigation Link"],
    ["dimensions", "Dimensions"],

];

export const foodCategoryExportData = [
    ["no", "Food Category Id"],
    ["category", "Food Category Name"],

];

export const postExportData = [
    ["no", "Post Report Id"],
    ["name", "Club Name"],
    ["userName", "User Name"],
    ["image", "Reported Post"],

];
