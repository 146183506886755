import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { bouncerExportData } from "../../../../util/exportData";

const Bouncers = () => {
  return (
    <>
      <PageComponent
        exportCSV
        tableHeaders="BOUNCERS"
        tableTitle="Bouncers List"
        formData
        addAPI={CONSTANTS.API.addBouncers}
        addModalTitle="Add New Bouncer"
        modalButton="Add New Bouncer"
        modalFields="BOUNCERS_MODAL"
        getAPI={CONSTANTS.API.getBouncers}
        extraResData="bouncers"
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              isBlockedD: data?.isBlocked ? 'Yes' : 'No',
            };
          });
        }}
        editformData
        editModalFields="EDIT_BOUNCERS_MODAL"
        editModalTitle="Edit Bouncer"
        editAPI={CONSTANTS.API.editBouncers}
        blockData
        deleteAPI={CONSTANTS.API.deleteBouncers}
        deleteData
        exportData={bouncerExportData}
      />
    </>
  );
};

export default Bouncers;
