import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import { eventExportData } from "../../../../util/exportData";



const filterList = [
  {
    id: 1,
    label: "Select All",
    value: '',
  },
  {
    id: 2,
    label: "Paid Events",
    value: "isPaid=1",
  },
  {
    id: 3,
    label: "Unpaid Events",
    value: "isPaid=0",
  },
];
const EventsList = () => {
  return (
    <div>
      <PageComponent
        exportCSV
        tableHeaders="EVENTS"
        tableTitle="Events List"
        getAPI={CONSTANTS.API.getAllEvents}
        extraResData="events"
        getData={(res) => {
          // console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              no: `E${data?.id.toString().padStart(7, "0")}`,
              date: moment
                .utc(data?.startDate, "YYYY-MM-DD")
                .local()
                .format("DD MMM, YYYY"),
              time: moment
                .utc(data?.startDate, "HH:mm:ss")
                .local()
                .format("hh:mm A"),
              userName: data?.User?.name,
              fees: `₹${data?.fees}`,
              revenue: `₹${data?.bookingRevenue || 0}`,
            };
          });
        }}
        filterparmas
        filterList={filterList}
        exportData={eventExportData}

      />

    </div>
  );
};

export default EventsList;
