import React, { useState } from "react";
import { Col, Image, Modal, Row } from "antd";
import PageComponent from "../../../../component/common/Page-Component";
import profile from "../../../../asset/image/image 2.png";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator } from "../../../../util/functions";
import { myEventExportData } from "../../../../util/exportData";


const Events = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [bookingDetails, setBookingDetails] = useState({});
  const [usersList, setUsersList] = useState([]);

  const api = useHttp();
  const showNotificationModal = (dataId) => {
    setIsModalOpen((prev) => !prev);
    // console.log(dataId);
    const EVENT_DETAIL_API = apiGenerator(CONSTANTS.API.getOneEvent, {
      dataId,
    });
    api.sendRequest(EVENT_DETAIL_API, (res) => {
      // console.log(res?.data?.event);
      setEventDetail({
        name: res?.data?.event?.name,
        image: res?.data?.event?.image,
        dateTime: `${moment(res?.data?.event?.startDate).format(
          "DD MMM"
        )}, ${moment(res?.data?.event?.startDate).format("hh:mm: A")}`,
        endDateTime: `${moment(res?.data?.event?.endDate).format(
          "DD MMM"
        )}, ${moment(res?.data?.event?.endDate).format("hh:mm: A")}`,
        description: res?.data?.event?.description,
        venue: res?.data?.event?.Club?.address || res?.data?.event?.venue,
        ticketPrice: res?.data?.event?.fees?.toLocaleString("en-In", {
          maximumFractionDigits: 0,
        }),
      });
    });

    const EVENTS_USERS_API = { ...CONSTANTS.API.getTodayEventsUsers };
    EVENTS_USERS_API.endpoint = `${EVENTS_USERS_API.endpoint}?EventId=${dataId}`;
    api.sendRequest(EVENTS_USERS_API, (res) => {
      // console.log(res?.data);
      setUsersList(
        res?.data?.tickets?.map((data) => {
          return {
            ...data,
            name: data?.User?.name,
            image: data?.User?.profilePic,
          };
        })
      );
      setBookingDetails({
        bookings: res?.data?.event?.bookingCount,
        tickets: res?.results,
        totalAmount: res?.data?.event?.bookingRevenue.toLocaleString("en-In", {
          maximumFractionDigits: 0,
        }),
      });
    });
  };
  return (
    <>
      <div>
        <PageComponent
          exportCSV
          tableHeaders="MY_EVENTS"
          tableTitle="Event List"
          getAPI={CONSTANTS.API.getMyEvents}
          extraResData="events"
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `ME${data?.id.toString().padStart(7, "0")}`,
                startDateWithTime: `${moment(data?.startDate).format(
                  "DD MMM, YYYY"
                )} & ${moment(data?.startDate).format("hh:mm: A")}`,
                endDateWithTime: `${moment(data?.endDate).format(
                  "DD MMM, YYYY"
                )} & ${moment(data?.endDate).format("hh:mm: A")}`,
              };
            });
          }}
          formData
          dateTime
          addAPI={CONSTANTS.API.addMyEvents}
          viewData
          viewFunction={showNotificationModal}
          addModalTitle="Add New Event"
          modalButton="Add New Event"
          modalFields="MY_EVENTS_MODAL"
          editModalTitle="Edit Event"
          editModalFields="EDIT_MY_EVENTS_MODAL"
          editformData
          editAPI={CONSTANTS.API.editMyEvents}
          deleteAPI={CONSTANTS.API.deleteMyEvents}
          deleteData
          exportData={myEventExportData}
        />
      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={isModalOpen}
        onOk={() => setIsModalOpen((prev) => !prev)}
        onCancel={() => setIsModalOpen((prev) => !prev)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Row>
          <Col span={12}>
            <div className="flex">
              <Image
                className="rounded-full object-fill"
                src={eventDetail?.image || profile}
                preview
                width={50}
                height={50}
              />
              <h2 className="mt-1 pl-3">{eventDetail?.name}</h2>
            </div>
          </Col>
          <Col span={12}>
            <div className="mt-1.5 pl-16">
              <span className="text-base text-slate-500 ">Ticket Price</span>
              <span className="text-xl font-medium pl-2">
                ₹{eventDetail?.ticketPrice}
              </span>
            </div>
          </Col>
        </Row>
        <section className="mt-5">
          <p className="text-slate-500 m-0 font-medium"><span className="font-normal">Start Date & Time</span> : {eventDetail?.dateTime}</p>
          <p className="text-slate-500 font-medium"><span className="font-normal">End Date & Time</span> : {eventDetail?.endDateTime}</p>
          <p className="text-slate-500 font-light -mt-3">
            {eventDetail?.venue}
          </p>
          <p className="text-slate-500 font-light -mt-3">
            <span className="font-normal">Event Description</span> : {eventDetail?.description}
          </p>
        </section>
        <section>
          <div className="flex justify-between ">
            <h2 className="inline-block"> {bookingDetails?.bookings || 0} Bookings</h2>
            <h2 className="inline-block"> {bookingDetails?.tickets || 0} Tickets</h2>
            <div className="mt-1 mr-5">
              <span className="text-base text-slate-500 ">Total</span>
              <span className="text-xl font-medium pl-2">₹{bookingDetails?.totalAmount || 0}</span>
            </div>
          </div>
          {/* Headers */}
          <Row>
            <Col span={4}>
              <p className="text-slate-500 font-light">Image</p>
            </Col>
            <Col span={8}>
              <p className="text-slate-500 font-light">Name</p>
            </Col>
          </Row>
          {/* Data */}
          {usersList?.map((ele) => {
            return (
              <div key={ele?.id}>
                <Row>
                  <Col span={4}>
                    <Image
                      className="rounded-full"
                      src={ele.image || profile}
                      preview
                      width={40}
                      height={40}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="text-xl mt-2">
                      {ele?.name}
                    </p>
                  </Col>
                </Row>
                <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
              </div>
            );
          })}
        </section>
      </Modal>
    </>
  );
};

export default Events;
