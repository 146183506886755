import React, { useState } from "react";
import { Col, Dropdown, Image, Modal, Pagination, Row, Space } from "antd";
import {
  DeleteTwoTone,
  EditOutlined,
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { HiOutlineDotsVertical } from "react-icons/hi";
import profile from "../../../../asset/image/image 2.png";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";


const Post = ({
  id = null,
  file = null,
  userId = "No Data!",
  description = "No Data!",
  userProfile,
  mediaType,
  thumbnail,
  setReload,
  totalRecords,
  currentPage,
  pageSize,
  setPageSize,
  setCurrentPage
}) => {
  const [editPost, setEditPost] = useState(null)
  const [deletePost, setDeletePost] = useState(null)
  const [showMore, setShowMore] = useState(false);

  const api = useHttp();

  // console.log(file);
  // console.log(thumbnail);
  // Delete Post Data




  const items = [
    {
      label: <div><EditOutlined className="pr-2" /> Edit </div>,
      key: 'edit',
    },
    {
      label:
        <div style={{ color: '#D72317' }}><DeleteTwoTone className="pr-2" twoToneColor='#D72317' /> Delete</div>
      ,
      key: 'delete',
    },

  ];

  // DropDown Handler
  const dropDownHandler = ({ key }) => {
    if (key === 'edit') {
      setEditPost({
        id, file, description, thumbnail, mediaType
      })
    }
    else {
      setDeletePost({ id });
    }
  }


  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderContent = () => {
    if (showMore) {
      return description;
    }


    if (description?.split(" ")?.length > 7) {
      return description?.split(" ")?.slice(0, 7)?.join(" ")?.concat("...");

    }
    else {
      return description;
    }
  };

  // Edit Post Data
  const editPostData = (value) => {
    let mediaType = null;
    if (value?.fileImage !== undefined) {

      mediaType = ((value?.fileImage[0]?.type)?.split('/'))[0] === 'video' ? 'Video' : 'Image';
    }
    const payload = new FormData();
    CONSTANTS.FORM_FIELD.EDIT_POST_UPLOAD_MODAL.forEach(ele => {

      if (
        ele.type !== "post"
      ) {
        // console.log(value[ele.id]);
        payload.append(ele.id, value[ele.id]);
        mediaType && payload.append('mediaType', mediaType)
      }
      if (ele.type === "post") {

        if (value['fileImage'] !== undefined) {
          if (value['fileImage'] && value['fileImage'][0]?.type === 'video/mp4') {
            payload.append('file', value['fileImage'][0]?.originFileObj);
            payload.append('thumbnail', value['thumbnailImage'][0]?.originFileObj);

          }
          else {
            payload.append('file', value['fileImage'][0]?.originFileObj);
          }
        }
      }
    })
    const EDIT_POST_API = { ...CONSTANTS.API.editClubPost }
    EDIT_POST_API.endpoint = EDIT_POST_API.endpoint.replace(':dataId', editPost?.id)
    api.sendRequest(EDIT_POST_API, () => {
      setEditPost(null)
      setReload(prev => !prev)
    }, payload, 'Edit Post Successfully!!!')

  }

  // Delete Data

  const deletePostData = () => {
    // console.log('deletePost');
    // console.log(deletePost, 'in function');
    const DELETE_POST_API = { ...CONSTANTS.API.deleteClubPost }
    DELETE_POST_API.endpoint = DELETE_POST_API.endpoint.replace(':dataId', deletePost?.id)
    api.sendRequest(DELETE_POST_API, () => {
      setDeletePost(null)
      setReload(prev => !prev)
    }, {}, 'Delete Post Successfully!!!')
  }




  return (
    <>
      <ModalFormCreator
        open={editPost !== null}
        onCreate={editPostData}
        onCancel={() => {
          setEditPost(null);
        }}
        name="Edit Post Details"
        menu="EDIT_POST_UPLOAD_MODAL"
        formData={editPost}
      />

      <Modal title="Are Sure you want to Delete a Post?" open={deletePost !== null} onOk={deletePostData} onCancel={() => { setDeletePost(null) }}>

      </Modal>

      <div
        className="mx-2"
      // style={{ border: "2px solid black", display: "block" }}
      >
        <section className="mb-16">
          <Row className="mt-5">
            <Col span={3}>
              <Image
                src={userProfile || profile}
                preview
                className="rounded-full block"
                width={35}
                height={35}
              />
            </Col>
            <Col span={20}>
              <p className="mt-1.5 font-bold">{userId}</p>
            </Col>
            <Col span={1}>
              <div>
                <Dropdown
                  menu={{
                    items,
                    onClick: dropDownHandler
                  }}
                  trigger={['click']}

                >
                  <HiOutlineDotsVertical className="cursor-pointer" />
                </Dropdown>

              </div>
            </Col>
          </Row>
          {(mediaType === 'Video' && file) && (
            <div className="flex justify-center items-center my-3">
              <Image

                alt="image not found"
                preview={{
                  imageRender: () => (
                    <video
                      fileer={thumbnail}
                      width="80%"
                      controls
                      src={file}
                    // src='https://assets.mixkit.co/videos/preview/mixkit-small-turtles-basking-in-the-sun-12813-large.mp4'
                    />
                  ),
                  toolbarRender: () => null,
                }}
                src={thumbnail}
                width={300}
                height={300}
                className="rounded-2xl"
              />
            </div>
          )}
          {(mediaType === 'Image' && file) && (
            <div className="flex justify-center items-center my-3">
              <Image
                src={file}
                alt="image not found"
                width={300}
                height={300}
                className="rounded-2xl"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      {/* <DownloadOutlined onClick={() => {
                      }} /> */}
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            </div>
          )}
          {description && <p className={file ? "-mb-1" : "mt-6"}>{renderContent()}</p>}
          {description?.split(" ").length > 7 && (
            <p
              className="bg-transparent cursor-pointer border-none -mt-2 float-right"
              onClick={toggleShowMore}
            >
              {showMore ? " less" : "more"}
            </p>
          )}



        </section>

      </div>
    </>
  );
};

export default Post;
