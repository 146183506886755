import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import PageComponent from "../../../component/common/Page-Component";
import { useNavigate } from "react-router-dom";
import { managerExportData } from "../../../util/exportData";

const Manager = () => {
  const navigatie = useNavigate();

  const mangerChat = (id) => {
    navigatie(`/app/staff/manager/${id}`);
  }

  return (
    <>
      <div>
        <PageComponent
          exportCSV
          tableHeaders="MANAGER"
          tableTitle="Manger List"
          formData
          addAPI={CONSTANTS.API.addManagers}
          addModalTitle="Add New Manger"
          modalButton="Add New Manger"
          modalFields="MANAGER_MODAL"
          getAPI={CONSTANTS.API.getManagers}
          extraResData="managers"
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `M${data?.id.toString().padStart(7, "0")}`,
                isBlockedD: data?.isBlocked ? 'Yes' : 'No',
              };
            });
          }}
          editformData
          editModalFields="EDIT_MANAGER_MODAL"
          editModalTitle="Edit Manger"
          editAPI={CONSTANTS.API.editManagers}
          blockData
          deleteAPI={CONSTANTS.API.deleteManagers}
          deleteData
          viewData
          viewFunction={mangerChat}
          exportData={managerExportData}
        />
      </div>
    </>
  );
};

export default Manager;
