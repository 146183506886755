import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { valetExportData } from "../../../../util/exportData";

const Valets = () => {
  return (
    <>
      <PageComponent
        exportCSV
        tableHeaders="VALETS"
        tableTitle="Valets List"
        formData
        addAPI={CONSTANTS.API.addValets}
        addModalTitle="Add New Valets"
        modalButton="Add New Valets"
        modalFields="VALETS_MODAL"
        getAPI={CONSTANTS.API.getValets}
        extraResData="valets"
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              isBlockedD: data?.isBlocked ? 'Yes' : 'No',
            };
          });
        }}
        editformData
        editModalFields="EDIT_VALETS_MODAL"
        editModalTitle="Edit Valet"
        editAPI={CONSTANTS.API.editValets}
        blockData
        deleteAPI={CONSTANTS.API.deleteValets}
        deleteData
        exportData={valetExportData}
      />
    </>
  );
};

export default Valets;
