import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Row, Card } from 'antd';
import useHttp from '../../../hooks/use-http';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { useNavigate } from 'react-router-dom';

const ClubActivity = () => {
  const [clubData, setClubData] = useState({});
  const navigate = useNavigate();
  const api = useHttp();
  // console.log(clubData);


  useEffect(() => {


    const GET_ANALYTICS_API = { ...CONSTANTS.API.getClubAnalytics }
    api.sendRequest(GET_ANALYTICS_API, (res) => {
      // console.log(res?.data?.club);
      setClubData({
        managers: res?.data?.club?.managers,
        bouncers: res?.data?.club?.bouncers,
        valets: res?.data?.club?.valets,
        events: res?.data?.club?.events,
        entries: res?.data?.club?.todaysEntries,
        sold: res?.data?.club?.todayTicketSold,
        visits: res?.data?.club?.totalVisits,
        accepted: res?.data?.club?.acceptedEvents,
        pending: res?.data?.club?.pendingEvents,
        rejected: res?.data?.club?.rejectedEvents,
        sales: res?.data?.club?.todayTicketSells,
        bookings: res?.data?.club?.todayBookings,
      });
    })

  }, [])
  return (
    <>
      <div className="my-6">
        <Row gutter={[16, 16]}>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/staff/manager')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Managers
                </p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.managers || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/staff/bouncers')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Bouncers
                </p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.bouncers || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/staff/valets')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">Valets</p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.valets || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/events/my-events')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">Events</p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.events || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/visits')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">Visits</p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.visits || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/events/approved-event')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Accepted Events
                </p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.accepted || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/events/request')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Pending Events
                </p>
                <p className="pt-2 font-medium text-xl">{clubData?.pending}</p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className='cursor-pointer' onClick={() => {
            navigate('/app/events/rejected-event')
          }}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Rejected Events
                </p>
                <p className="pt-2 font-medium text-xl">
                  {clubData?.rejected || 0}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Today&lsquo;s Entries
                </p>
                <p className="pt-2 font-medium text-xl">{+clubData?.entries}</p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} >
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Today&lsquo;s Bookings
                </p>
                <p className="pt-2 font-medium text-xl">
                  {+clubData?.bookings}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} >
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Today&lsquo;s Ticket Solds
                </p>
                <p className="pt-2 font-medium text-xl">
                  {+clubData?.sold}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} >
            <Card size="small" className="p-5">
              <div>
                <p className="font-semibold text-base text-slate-400">
                  Today&lsquo;s Ticket sales
                </p>
                <p className="pt-2 font-medium text-xl">
                  {+clubData?.sales}
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ClubActivity