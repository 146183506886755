import React from 'react'
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';
import { visitsExportData } from '../../../util/exportData';

const Visits = () => {
  return (
    <div>
      <PageComponent
        exportCSV
        tableHeaders="VISITS"
        tableTitle="Visits List"
        getAPI={CONSTANTS.API.getVisits}
        extraResData="visitLedgers"
        getData={(res) => {
          return res?.map((data) => {
            // console.log(res);
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              caption: data?.experience,
              userName: data?.User?.name,
              // date: moment.utc(data?.startDate).local().format("DD MMM, YYYY"),
              // time: moment.utc(data?.startDate).local().format("hh:mm A"),
              dateTime: `${moment
                .utc(data?.createdAt)
                .local()
                .format("DD MMM, YYYY")}, ${moment
                  .utc(data?.createdAt)
                  .local()
                  .format("hh:mm A")}`,
            };
          });
        }}
        datefilter
        exportData={visitsExportData}
      />
    </div>
  );
}

export default Visits