import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { foodCategoryExportData } from '../../../../util/exportData';

const FoodCategory = () => {
  return (
    <div>
      <PageComponent
        exportCSV
        tableHeaders="FOOD_CATEGORY"
        tableTitle="Food Category"
        getAPI={CONSTANTS.API.getFoodCategory}
        extraResData="foodCategories"
        getData={(res) => {
          return res?.map((data) => {
            // console.log(res);
            return {
              ...data,
              no: `FC${data?.id.toString().padStart(7, "0")}`,
            };
          });
        }}
        exportData={foodCategoryExportData}
      />
    </div>
  );
}

export default FoodCategory