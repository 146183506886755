import { Outlet, useNavigate } from "react-router-dom";

import { MenuOutlined } from "@ant-design/icons";
import { Avatar, Col, Image, Layout, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import Icon from "@ant-design/icons/lib/components/Icon";
import data from "../../util/constant/menu";
import CONSTANTS, { appRoot, loginRoot } from "../../util/constant/CONSTANTS";
import Profile from "../../asset/image/dummy-avatar.jpg";
import { getAuthToken } from "../../util/API/authStorage";
import logo from "../../asset/logos/icon.svg";
import useHttp from "../../hooks/use-http";

const { Header, Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  ...data.map((el) =>
    getItem(
      el.label,
      `/app/${el.id}`,
      el.icon,
      el.subMenu &&
      el.subMenu.map((elp) =>
        getItem(elp.label, `/app/${el.id}/${elp.id}`, elp.icon)
      )
    )
  ),
];
const AppLayout = () => {
  const navigate = useNavigate();
  const [UserData, setUserData] = useState({});

  const [collapsed, setCollapsed] = useState(true);
  const API = useHttp();


  // Private Route
  useEffect(() => {



    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot);
      return;
    }

    if (!CONSTANTS.GETMe) {
      API.sendRequest(CONSTANTS.API.getMyProfile, (res) => {
        // console.log(res, "API");
        CONSTANTS.GETMe = res?.data;
        setUserData(res?.data?.club);
      });
    }
  }, []);
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };



  return (
    <>
      <Layout
        hasSider={true}
        style={{
          minHeight: "100vh",
          userSelect: "none",
        }}
      >
        <Sider
          trigger={null}
          // style={{
          //   overflow: "hidden",
          //   overflowY: "scroll",
          //   maxHeight: "100vh",
          // }}
          theme="light"
          collapsible
          collapsed={collapsed}
        >
          <div
            style={{
              display: "flex",
              height: "64px",
              paddingLeft: "30px",
              alignItems: "center",
            }}
          >
            <Icon
              style={{ fontSize: "20px" }}
              component={MenuOutlined}
              onClick={toggleCollapsed}
            ></Icon>
          </div>
          {/* <div style={{ height: "60px" }}>
            <Row
              justify="center"
              style={{
                opacity: Object.keys(UserData).length ? (collapsed ? 0 : 1) : 0,
                transition: "all 1s ease-in",
              }}
            >
              <Col span={6} style={{ display: collapsed ? "none" : "" }}>
                <Avatar src={Profile} size={35} shape="square" />
              </Col>
              <Col span={12} style={{ display: collapsed ? "none" : "" }}>
                <Row>
                  <p className="profile-head">Welcome</p>
                </Row>
                <Row
                  style={{
                    opacity: Object.keys(UserData).length
                      ? collapsed
                        ? 0
                        : 1
                      : 0,
                    transition: "all 1s ease-in",
                  }}
                >
                  <p className="profile-sub">{UserData?.name}</p>
                </Row>
              </Col>
            </Row>
          </div> */}
          <Menu
            defaultSelectedKeys={window.location.pathname}
            activeKey=""
            mode="inline"
            items={items}
            onClick={(e) => {
              // console.log(e);
              if (e.keyPath.length) {
                navigate(e.key);
              }
            }}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              backgroundColor: "#FFFFFF",
              justifyContent: "space-between",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{ padding: "0px 35px" }}
            >
              <Col span={6} className="center flex">
                <Image
                  style={{ height: "55px", width: "70px" }}
                  preview
                  src={logo}
                  className=""
                />
              </Col>
              {/* <Col span={12} style={{ height: "40px" }}>
                <Search className="dashboardSearch" />
              </Col> */}
              <Col
                span={18}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {/* <Badge dot>
                    <IoNotificationsOutline
                      size="2em"
                      color="#BFC5D2"
                      display="flex"
                    />
                  </Badge> */}
                  <Row
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`${appRoot}/dashboard`);
                    }}
                  >
                    <Col span={19}>
                      <p className="text-lg pt-6 lg:mr-2 xl:mr-2 font-medium">
                        {UserData?.clubUserName}
                      </p>
                    </Col>
                    <Col span={5}>
                      <Avatar
                        src={Profile}
                        size={35}
                        style={{
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`${appRoot}/dashboard`);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Outlet />

            {/* {getAuthToken() !== undefined && getAuthToken() !== null && (
              <Outlet />
            )} */}
          </Content>
        </Layout>
      </Layout>
      {/* <div> */}

      {/* </div> */}
    </>
  );
};

export default AppLayout;
