import React, { useEffect, useState } from "react";
import post from "../../../../asset/image/clubs.png";
import profile from "../../../../asset/image/image 2.png";
import Post from "./Post";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { Pagination } from "antd";




const PostTab = ({ refresh }) => {
  const [clubPosts, setClubPosts] = useState([]);
  const [clubDetails, setClubDetails] = useState({});
  const [reload, setReload] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [totalRecords, setTotalRecords] = useState(0);
  const api = useHttp();



  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <p className="px-3 hover:text-blue-500">Previous</p>;
    }
    if (type === 'next') {
      return <p className="px-3 hover:text-blue-500">Next</p>;
    }
    return originalElement;
  };

  const handleChangePage = (page, pageSize) => {

    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {

    const GET_CLUB_POST_API = { ...CONSTANTS.API.getClubPost };
    GET_CLUB_POST_API.endpoint = `${GET_CLUB_POST_API.endpoint}?page=${currentPage}&limit=${pageSize}`
    api.sendRequest(GET_CLUB_POST_API, (res) => {
      setClubPosts(res?.data?.posts);
      setTotalRecords(res?.totalData);
    })

    const GET_CLUB_DETAIL_API = { ...CONSTANTS.API.getMyProfile }
    api.sendRequest(GET_CLUB_DETAIL_API, (res) => {
      setClubDetails({
        ...res?.data?.club,
        clubUserName: res?.data?.club?.clubUserName,
        clubImage: res?.data?.club?.image
      })
    })

  }, [refresh, reload, currentPage])

  return (
    <>
      <div className="" >
        {clubPosts.map((ele) => {
          return (
            <div key={ele.id}>
              <Post
                id={ele.id}
                file={ele?.file}
                thumbnail={ele?.thumbnail}
                userProfile={clubDetails?.clubImage}
                userId={clubDetails?.clubUserName}
                description={ele?.description ?? ''}
                mediaType={ele?.mediaType}
                setReload={setReload}
                totalRecords={totalRecords}
                currentPage={currentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
              />

            </div>
          );
        })}

        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalRecords}
          onChange={handleChangePage}
          className="my-10 "
          size="small"
          showLessItems
          itemRender={itemRender}
        />
      </div>
    </>
  );
};

export default PostTab;
