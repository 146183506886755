import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import Bouncers from "../page/app/staff/Bouncers";
import Valets from "../page/app/staff/Valets";
import Manager from "../page/app/staff";
import Request from "../page/app/Events/Request";
import EventsList from "../page/app/Events/Events List";
import ClubProfile from "../page/app/club-profile";
import ClubActivity from "../page/app/club-activity";
import TodayEvent from "../page/app/Events";
import ApproveEvent from "../page/app/Events/Approved Events";
import Events from "../page/app/Events/My Events";
import Visits from "../page/app/visits";
import QRCode from "../page/app/qr-code";
import TodayEventDetails from "../page/app/Events/today-event-detail";
import FoodList from "../page/app/floating-icons";
import FoodCategory from "../page/app/floating-icons/food-category";
import Banner from "../page/app/floating-icons/Banner";
import MangerChatHistory from "../page/app/staff/manger-chat-history";
import Chat from "../page/app/staff/chat";
import RejectedEvents from "../page/app/Events/Rejected Events";
import Report from "../page/app/report";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  CLUB_PROFILE: "/dashboard",
  CLUB_ACTIVITY: "/club-activity",
  STAFF: "/staff",
  MANAGER: "/manager",
  MANAGER_CHAT_HISTORY: "/manager/:id",
  MANAGER_CHAT: "/manager/:id/:chatId",
  USERS: "/users",
  USERS_DETAIL: "/users/:id",
  CLUBS: "/clubs",
  REQUEST: "/request",
  BOUNCERS: "/bouncers",
  VALETS: "/valets",
  TODAY_EVENTS: "/today-events",
  TODAY_EVENTS_USERS: "/today-events/:id",
  EVENTS: "/events",
  MY_EVENTS: "/my-events",
  REQUEST: "/request",
  REJECTED_EVENTS: "/rejected-event",
  APPROVED_EVENT: "/approved-event",
  EVENTS_LIST: "/events-list",
  VISITS: "/visits",
  FLOATING_ICONS: "/floating-icons",
  FOOD_LIST: "/food-list",
  FOOD_CATEGORY: "/food-category",
  BANNER: "/banner",
  QRCODE: '/qrcode',
  NOTIFICATIONS: '/notifications',
  REPORT: '/report',


};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={`${appRoot}${ROUTES.CLUB_ACTIVITY}`} />,
      },
      {
        path: `${appRoot}${ROUTES.CLUB_ACTIVITY}`,
        element: <ClubActivity />,
      },
      {
        path: `${appRoot}${ROUTES.CLUB_PROFILE}`,
        element: <ClubProfile />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}`,
        element: <Navigate to={`${appRoot}${ROUTES.STAFF}${ROUTES.MANAGER}`} />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}${ROUTES.MANAGER}`,
        element: <Manager />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}${ROUTES.MANAGER_CHAT_HISTORY}`,
        element: <MangerChatHistory />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}${ROUTES.MANAGER_CHAT}`,
        element: <Chat />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}${ROUTES.BOUNCERS}`,
        element: <Bouncers />,
      },
      {
        path: `${appRoot}${ROUTES.STAFF}${ROUTES.VALETS}`,
        element: <Valets />,
      },

      {
        path: `${appRoot}${ROUTES.EVENTS}`,
        element: (
          <Navigate to={`${appRoot}${ROUTES.EVENTS}${ROUTES.TODAY_EVENTS}`} />
        ),
      },

      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.TODAY_EVENTS}`,
        element: <TodayEvent />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.TODAY_EVENTS_USERS}`,
        element: <TodayEventDetails />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.MY_EVENTS}`,
        element: <Events />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.REQUEST}`,
        element: <Request />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.REJECTED_EVENTS}`,
        element: <RejectedEvents />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.EVENTS_LIST}`,
        element: <EventsList />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}${ROUTES.APPROVED_EVENT}`,
        element: <ApproveEvent />,
      },
      {
        path: `${appRoot}${ROUTES.VISITS}`,
        element: <Visits />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}`,
        element: (
          <Navigate
            to={`${appRoot}${ROUTES.FLOATING_ICONS}${ROUTES.FOOD_LIST}`}
          />
        ),
      },
      {
        path: `${appRoot}${ROUTES.FLOATING_ICONS}${ROUTES.FOOD_LIST}`,
        element: <FoodList />,
      },
      {
        path: `${appRoot}${ROUTES.FLOATING_ICONS}${ROUTES.FOOD_CATEGORY}`,
        element: <FoodCategory />,
      },
      {
        path: `${appRoot}${ROUTES.FLOATING_ICONS}${ROUTES.BANNER}`,
        element: <Banner />,
      },
      {
        path: `${appRoot}${ROUTES.QRCODE}`,
        element: <QRCode />,
      },
      {
        path: `${appRoot}${ROUTES.REPORT}`,
        element: <Report />,
      },

    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
