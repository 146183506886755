import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Col, Image, Row } from 'antd'
import imageSrc from "../../../asset/image/image 2.png";
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import useHttp from '../../../hooks/use-http';
import { apiGenerator } from '../../../util/functions';

const TodayEventDetails = () => {
  const [eventDetail, setEventDetail] = useState({});
  const { id } = useParams();
  const dataId = id;
  const api = useHttp();
  // console.log(id);
  useEffect(() => {
    const EVENT_DETAIL_API = apiGenerator(CONSTANTS.API.getOneEvent, {
      dataId,
    });
    api.sendRequest(EVENT_DETAIL_API, (res) => {
      // console.log(res?.data?.event);
      setEventDetail({
        name: res?.data?.event?.name,
        image: res?.data?.event?.image,
      });
    })


  }, [])
  return (
    <>
      <div>
        <Row gutter={[16, 16]} className="mt-5">
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card size="small" className="p-5">
              <Row>
                <Col>
                  <Image
                    width={80}
                    height={80}
                    className="rounded-full"
                    preview
                    src={eventDetail?.image || imageSrc}
                    alt="Images is Not Availabel"
                  />
                </Col>
                <Col>
                  <p className="text-2xl pt-7 pl-3 font-medium">
                    {eventDetail?.name || "No data found"}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <PageComponent
          exportCSV
          tableTitle="User List"
          tableHeaders="TODAY_EVENTS_USER"
          getAPI={CONSTANTS.API.getTodayEventsUsers}
          extraParams={`&EventId=${id}`}
          extraResData="tickets"
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `U${data?.id.toString().padStart(7, "0")}`,
                name: data?.User?.name,
                image: data?.User?.profilePic,
                mobile: data?.User?.mobile,
                userName: data?.User?.userName,
              };
            });
          }}
        />
      </div>
    </>
  );
}

export default TodayEventDetails