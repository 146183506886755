import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import CONSTANTS, {
  appRoot,
} from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import {
  getAuthToken,
  setAuthDetails,
} from "../../../util/API/authStorage";
import logo from "../../../asset/logos/icon.svg";
import axios from "axios";

const LogIn = () => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [MOBILE_TOKEN, setMOBILE_TOKEN] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
    // console.log(isLogin, "loh", getAuthToken());
    if (isLogin) {
      // console.log(isLogin, "loh2", getAuthToken());
      navigate(appRoot);
    }
  }, []);
  // navigate(appRoot);
  const api = useHttp();

  const [isValid, setIsValid] = useState(false);

  const submitMobileData = (e) => {
    const payload = {
      mobile: +e.mobile,
      fromDashBoard: true
    };
    // console.log(payload);
    api.sendRequest(
      CONSTANTS.API.sendOTP,
      (res) => {
        setMOBILE_TOKEN(res?.token);
        // MOBILE_TOKEN.token = res?.token
        setIsValid((prev) => !prev);
        formRef.current.focus();
      },
      payload,
      "OTP Sent on your Contact Number"
    );
  };

  // console.log(getAuthToken());

  const verifyOTP = (e) => {
    setIsLoading((prev) => !prev);
    // console.log("clicked");
    // console.log(e);
    const payload = {
      OTP: e.OTP,
      fromDashBoard: true
    };

    // console.log(payload);
    (async () => {
      const Services = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // timeout: 10000,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${MOBILE_TOKEN}`,
        },
      });
      try {
        const response = await Services.post("/clubs/verify/OTP", payload);
        // console.log(response, 'mobile');

        if (response?.data?.isNewClub) {
          form.resetFields()
          setIsValid(false)
          notification.info({ message: 'Complete the onboarding process from the Bash business app' })
          return;
        }
        setAuthDetails(response?.data?.token);


        // window.location.assign(appRoot);
        const Services2 = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          // timeout: 10000,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${response?.data?.token}`,
          },
        });
        try {
          const responseData = await Services2.get("/clubs/profile");
          // console.log(responseData?.data?.data?.club?.id);
          // console.log(responseData?.data?.data);
          setIsLoading(prev => !prev)
          localStorage.setItem("clubId", responseData?.data?.data?.club?.id);
          notification.success({
            message: "Login Successfully!!!",
            duration: "2",
          });


          window.location.assign(appRoot);

        } catch (err) {
          setIsLoading((prev) => !prev);
          if (err?.response?.data?.message) {
            notification.error({
              message: err?.response?.data?.message,
              duration: "3",
            });
          } else {
            notification.error({
              message: "Something Wrong Please Try again",
            });
          }
        }

      } catch (err) {
        setIsLoading((prev) => !prev);
        if (err?.response?.data?.message) {
          notification.error({
            message: err?.response?.data?.message,
            duration: "3",
          });
        } else {
          notification.error({
            message: "Something Wrong Please Try again",
          });
        }
      }
    })();



  };


  return (
    <>

      <div
        className="h-screen flex justify-center  items-center"
        style={{ background: "#121212" }}
      >
        <Row className="">
          <Col span={10} sm={24} xs={24} md={10} lg={10}>
            <div className="mr-10 mt-14 flex content-center justify-center">
              <Image
                src={logo}
                alt="Bash"
                preview
                width={300}
                className="p-5"
              />
            </div>
          </Col>
          <Col span={14} sm={24} xs={24} md={14} lg={14}>
            <Card className="shadow-2xl" style={{ background: "#202020", border: "none" }}>
              <p className="text-3xl font-medium	text-white">Log in</p>
              {/* <FormWithButton
                menu="LOGIN_PAGE_MODAL"
                name="Send OTP"
                onCreate={(element) => {
                  console.log(element);
                }}
                inline={false}
              /> */}
              <Form form={form} className="text-white" onFinish={submitMobileData}>
                <Form.Item
                  name="mobile"
                  rules={[
                    // {
                    //   required: data?.required,
                    //   message: "Please Enter valid mobile number",
                    // },
                    {
                      required: true,
                      message: "Please Enter Mobile Number!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && /^\d{10}$/.exec(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter valid mobile number")
                        );
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    name="mobile"
                    id="mobile"
                    placeholder="Enter Mobile Number"
                    className={`${api.isLoading
                      ? "w-full bg-white text-slate-500"
                      : "w-full"
                      }`}
                    pattern="[1-9]{1}[0-9]{9}"
                    maxLength={10}
                    disabled={api.isLoading}
                  />
                </Form.Item>
                {!isValid && (
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="block m-auto"
                      type="primary"
                      loading={api.isLoading}
                      style={{
                        background: 'var(--greadient, linear-gradient(90deg, #FE8C00 0%, #F83600 100%))',
                        boxShadow: '0px 5px 18px 0px rgba(0, 0, 0, 0.20)'
                      }}
                    >
                      Verify Mobile Number
                    </Button>
                  </Form.Item>
                )}
              </Form>
              {isValid && (
                <Form onFinish={verifyOTP}>
                  <Form.Item
                    ref={formRef}
                    name="OTP"
                    rules={[
                      {
                        required: true,
                        message: "Invalid OTP!",
                      },
                      () => ({
                        validator(_, value) {
                          // console.log(value);
                          if (value && /^[0-9]+$/.exec(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please Enter valid OTP")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password

                      name="OTP"
                      id="OTP"
                      type="password"
                      placeholder="Enter OTP"
                      className={`${isLoading
                        ? "w-full bg-white  text-slate-500"
                        : "w-full"
                        }`}
                      disabled={api.isLoading}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="block m-auto"
                      type="primary"
                      loading={api.isLoading}
                      style={{
                        background: 'var(--greadient, linear-gradient(90deg, #FE8C00 0%, #F83600 100%))',
                        boxShadow: '0px 5px 18px 0px rgba(0, 0, 0, 0.20)'
                      }}
                    >
                      Verify OTP
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
        {/* <Registration /> */}
      </div>
    </>
  );
};
export default LogIn;
