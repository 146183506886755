import React, { useState } from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { Col, Image, Modal, Row } from "antd";
import profile from "../../../../asset/image/image 2.png";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator } from "../../../../util/functions";
import { approvedEventExportData } from "../../../../util/exportData";

const ApproveEvent = () => {
  const [eventDetail, setEventDetail] = useState({});
  const [userEventModal, setUserEventModal] = useState(false)
  const [eventUserList, setEventUserList] = useState([]);
  const [moderator, setModerator] = useState({});
  const api = useHttp()

  const eventDetailsNotification = (dataId) => {


    // Event Detail API

    const EVENT_DETAIL_API = apiGenerator(CONSTANTS.API.getOneEvent, {
      dataId,
    });
    api.sendRequest(EVENT_DETAIL_API, (res) => {
      setUserEventModal(prev => !prev)


      setEventDetail({
        ...res?.data?.event,
        name: res?.data?.event?.name,
        image: res?.data?.event?.image,
        dateTime: `${moment(res?.data?.event?.startDate).format(
          "DD MMM"
        )}, ${moment(res?.data?.event?.startDate).format("hh:mm: A")}`,
        endDateTime: `${moment(res?.data?.event?.endDate).format(
          "DD MMM"
        )}, ${moment(res?.data?.event?.endDate).format("hh:mm: A")}`,
        description: res?.data?.event?.description,
        venue: res?.data?.event?.Club?.address || res?.data?.event?.venue,
        ticketPrice: res?.data?.event?.fees?.toLocaleString("en-In", {
          maximumFractionDigits: 0,
        }),
      });
    });



    // User Event User List
    const USER_EVENTS_USERS_API = { ...CONSTANTS.API.getRsvp };
    USER_EVENTS_USERS_API.endpoint = `${USER_EVENTS_USERS_API.endpoint}${dataId}?status=1`

    api.sendRequest(USER_EVENTS_USERS_API, (res) => {
      setModerator(res?.data?.event?.User);
    })

    api.sendRequest(USER_EVENTS_USERS_API, (res) => {
      setEventUserList(res?.data?.rsvps?.map((data) => {
        return {
          ...data,
          name: data?.User?.name,
          image: data?.User?.profilePic,
        };
      }));
    })



  };
  return (
    <>
      <div>
        <PageComponent
          exportCSV
          tableTitle="Approved Event List"
          tableHeaders="APPROVED_EVENTS"
          getAPI={CONSTANTS.API.getApproovedEvents}
          extraResData="events"
          getData={(res) => {
            return res?.map((data) => {
              // console.log(res);
              return {
                ...data,
                no: `AE${data?.id.toString().padStart(7, "0")}`,
                time: moment.utc(data?.startDate).local().format("hh:mm A"),
                date: moment(data?.startDate).format("DD MMM, YYYY"),
                fees: `₹${data?.fees}`,
                userName: data?.User?.userName
              };
            });
          }}
          viewData
          viewFunction={eventDetailsNotification}
          exportData={approvedEventExportData}
        />
      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={userEventModal}
        onOk={() => setUserEventModal(false)}
        onCancel={() => setUserEventModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div className="flex">
          <Image
            className="rounded-full"
            src={eventDetail?.image || profile}
            preview
            width={50}
            height={50}
          />
          <h2 className="mt-1 pl-3">{eventDetail?.name}</h2>
        </div>
        <section className="mt-5">
          <p className="text-slate-500 m-0 font-medium"><span className="font-normal">Start Date & Time</span> : {eventDetail?.dateTime}</p>
          <p className="text-slate-500 font-medium"><span className="font-normal">End Date & Time</span> : {eventDetail?.endDateTime}</p>
          <p className="text-slate-500 font-light -mt-3">
            {eventDetail?.venue}
          </p>
          <p className="text-slate-500 font-light -mt-3">
            <span className="font-normal">Event Description</span> : {eventDetail?.description}
          </p>
        </section>
        <section>
          <h3>Attendees</h3>
          {/* Headers */}
          <Row>
            <Col span={4}>
              <p className="text-slate-500 font-light">Image</p>
            </Col>
            <Col span={8}>
              <p className="text-slate-500 font-light">Name</p>
            </Col>

          </Row>
          <Row>
            <Col span={4}>
              <Image
                className="rounded-full"
                src={moderator?.profilePic || profile}
                preview
                width={40}
                height={40}
              />
            </Col>
            <Col span={8}>
              <p className="text-xl mt-2">{moderator?.name}</p>
            </Col>
            <Col span={11}>
              <p className="float-right  text-white px-4 py-1 rounded-lg text-xl mt-2" style={{ background: 'var(--greadient, linear-gradient(90deg, #FE8C00 0%, #F83600 100%))' }} >Moderator</p>
            </Col>

          </Row>
          <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
          {/* Data */}
          {eventUserList?.map((ele) => {
            return (
              <div key={ele?.id}>
                <Row>
                  <Col span={4}>
                    <Image
                      className="rounded-full"
                      src={ele.image || profile}
                      preview
                      width={40}
                      height={40}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="text-xl mt-2">
                      {ele?.name}
                    </p>
                  </Col>
                </Row>
                <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
              </div>
            );
          })}
        </section>
      </Modal>
    </>
  );
}

export default ApproveEvent