import React, { useState } from 'react'
import { Button, Image, Popconfirm } from 'antd';
import {
    PlusOutlined
} from "@ant-design/icons";
import ModalFormCreator from '../../../../component/common/ModalFormCreator';
import useHttp from '../../../../hooks/use-http';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../../util/functions';


const UploadBanner = ({
    width = 0,
    height = 0,
    bannerNo = 0,
    imgSrc,
    setRefresh

}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editData, setEditData] = useState(null)
    const api = useHttp()


    const submitHandler = (value) => {
        const payload = new FormData()
        payload.append('image', value['image'][0].originFileObj)
        value['link'] && payload.append('link', value['link'])
        payload.append('dimensions', `${width} X ${height}`)
        payload.append('name', `banner-${bannerNo}`)
        // console.log(payload);
        const ADD_BANNER_API = { ...CONSTANTS.API.addBanner }
        api.sendRequest(ADD_BANNER_API, () => {
            setRefresh(prev => !prev)
            setIsModalOpen(prev => !prev)
        }, payload, "Add Data Successfully!!!")
    }

    const editDataHandler = (value) => {
        console.log(value);
        const payload = new FormData()
        value['image'] && payload.append('image', value['image'][0].originFileObj)
        value['link'] && payload.append('link', value['link'])
        payload.append('dimensions', `${width} X ${height}`)
        payload.append('name', `banner-${bannerNo}`)
        const EDIT_BANNER_API = apiGenerator(CONSTANTS.API.editBanner, { dataId: imgSrc?.id })
        api.sendRequest(EDIT_BANNER_API, () => {
            setRefresh(prev => !prev)
            setEditData(null)
        }, payload, "Edit Data Successfully!!!")
    }

    const deleteHandler = (dataId) => {
        const DELETE = apiGenerator(CONSTANTS.API.deleteBanner, { dataId })
        api.sendRequest(DELETE, () => {
            setRefresh(prev => !prev)
        }, {}, "Delete Data Successfully!!!")
    }



    return (
        <>

            <div className=''>
                {imgSrc ? <div key={imgSrc?.id} className=''>
                    <div className='' style={{
                        width: `${width}px`,
                        height: `${height}px`,
                    }}> <Image
                            className='object-cover rounded-md'
                            alt="banner"
                            style={{
                                width: `${width}px`,
                                height: `${height}px`,
                            }}
                            src={imgSrc?.image}
                        />
                    </div>
                    <div className='flex my-3 gap-2'>
                        <Button type='primary' onClick={() => {
                            setEditData({ ...imgSrc })
                        }}> Update</Button>
                        <Popconfirm title="Sure to Delete?" onConfirm={() => deleteHandler(imgSrc?.id)}>
                            <Button danger > Delete</Button>
                        </Popconfirm>
                    </div>
                </div>


                    : <div key={imgSrc?.id}>
                        <ModalFormCreator
                            open={isModalOpen}
                            onCreate={submitHandler}
                            onCancel={() => {
                                setIsModalOpen((prev) => !prev);
                            }}
                            name='Add New Banner'
                            menu='BANNER_MODAL'
                        />
                        <div
                            onClick={() => setIsModalOpen((prev) => !prev)}
                            className='flex flex-col justify-center cursor-pointer items-center rounded-md border-2 border-dashed bg-[#fafafa]' style={{
                                width: `${width}px`,
                                height: `${height}px`,
                            }}>
                            <p className='text-lg m-0 pt-1'>
                                <PlusOutlined /> Banner - {bannerNo}
                            </p>
                            <p className='text-xs mt-2.5'>{width}px X {height}px</p>
                        </div>
                    </div>}

                <ModalFormCreator
                    open={editData !== null}
                    onCreate={editDataHandler}
                    onCancel={() => setEditData(null)}
                    name='Edit Banner'
                    menu='EDIT_BANNER_MODAL'
                    formData={editData}
                />
            </div>
        </>
    );
}

export default UploadBanner