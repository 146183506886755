import { BiUser, BiCopy, BiCategory } from "react-icons/bi";
import { BsCalendarCheck, BsQrCodeScan } from "react-icons/bs";
import { FaUserTie, FaUserEdit } from "react-icons/fa";
import { IoNotificationsOutline, IoFastFoodOutline } from "react-icons/io5";
import { GiCarKey } from "react-icons/gi";
import { GrNotes } from "react-icons/gr";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdToday } from "react-icons/md";
import {
  RiCustomerService2Fill,
  RiCalendarEventFill,
  RiEye2Line,
} from "react-icons/ri";
import { TbCalendarCancel, TbReport } from "react-icons/tb";
import { TiGroup } from "react-icons/ti";
import { VscGraph } from "react-icons/vsc";

const data = [
  {
    id: "club-activity",
    icon: <VscGraph />,
    label: "Club Activity",
  },
  // {
  //   id: "dashboard",
  //   icon: <AiOutlineDashboard />,
  //   label: "Dashboard",
  // },
  {
    id: "staff",
    icon: <TiGroup />,
    label: "Staff",
    subMenu: [
      {
        id: "manager",
        icon: <FaUserTie />,
        label: "Manager",
      },
      {
        id: "bouncers",
        icon: <RiCustomerService2Fill />,
        label: "Bouncers",
      },
      {
        id: "valets",
        icon: <GiCarKey />,
        label: "Valets",
      },
    ],
  },
  {
    id: "events",
    icon: <RiCalendarEventFill />,
    label: "Events",
    subMenu: [
      {
        id: "today-events",
        icon: <MdToday />,
        label: "Today Events",
      },
      {
        id: "my-events",
        icon: <BiUser />,
        label: "My Events",
      },
      {
        id: "request",
        icon: <GrNotes />,
        label: "Request",
      },
      {
        id: "rejected-event",
        icon: <TbCalendarCancel />,
        label: "Rejected Event",
      },
      {
        id: "events-list",
        icon: <HiOutlineClipboardList />,
        label: "Events List",
      },
      {
        id: "approved-event",
        icon: <BsCalendarCheck />,
        label: "Approved Event",
      },
    ],
  },
  {
    id: "visits",
    icon: <FaUserEdit />,
    label: "Visits",
  },
  {
    id: "floating-icons",
    icon: <RiEye2Line />,
    label: "Floating Icons",
    subMenu: [
      {
        id: "food-list",
        icon: <IoFastFoodOutline />,
        label: "Food List",
      },
      {
        id: "food-category",
        icon: <BiCategory />,
        label: "Food Category",
      },
      {
        id: "banner",
        icon: <BiCopy />,
        label: "Banner",
      },
    ],
  },
  {
    id: "qrcode",
    icon: <BsQrCodeScan />,
    label: "QR Code",
  },
  {
    id: "report",
    icon: <TbReport />,
    label: "Report",
  },

];
export default data;
