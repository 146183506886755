import React, { useEffect, useState } from 'react'
import UploadBanner from './UploadBanner'
import { Card, Spin } from 'antd'
import CONSTANTS from '../../../../util/constant/CONSTANTS'
import PageComponent from '../../../../component/common/Page-Component'
import useHttp from '../../../../hooks/use-http'

const Banner = () => {
  const [bannerList, setBannerList] = useState({
    image_1: null,
    image_2: null,
    image_3: null,
    image_4: null,
    image_5: null,
  })
  const [refresh, setRefresh] = useState(false)
  const api = useHttp();

  useEffect(() => {
    const GET_BANNER_API = { ...CONSTANTS.API.getBanner }
    api.sendRequest(GET_BANNER_API, (res) => {
      setBannerList({
        image_1: res?.data?.banners?.filter(ele => ele?.name === 'banner-1')[0] || null,
        image_2: res?.data?.banners?.filter(ele => ele?.name === 'banner-2')[0] || null,
        image_3: res?.data?.banners?.filter(ele => ele?.name === 'banner-3')[0] || null,
        image_4: res?.data?.banners?.filter(ele => ele?.name === 'banner-4')[0] || null,
        image_5: res?.data?.banners?.filter(ele => ele?.name === 'banner-5')[0] || null,
      })

    })
  }, [refresh])
  return (
    <>
      <Card className='my-5 flex justify-center'>

        {api.isLoading ? <Spin size='large' /> : <div>
          <div className='my-10 flex gap-4'>
            <UploadBanner setRefresh={setRefresh} imgSrc={bannerList.image_1} width={304} height={102} bannerNo={1} />
            <UploadBanner setRefresh={setRefresh} imgSrc={bannerList.image_2} width={142} height={122} bannerNo={2} />
            <UploadBanner setRefresh={setRefresh} imgSrc={bannerList.image_3} width={142} height={122} bannerNo={3} />
          </div>
          <div className='flex gap-4'>
            <UploadBanner setRefresh={setRefresh} imgSrc={bannerList.image_4} width={304} height={171} bannerNo={4} />
            <UploadBanner setRefresh={setRefresh} imgSrc={bannerList.image_5} width={304} height={171} bannerNo={5} />
          </div>
        </div>}




      </Card>
    </>
  )
}

export default Banner