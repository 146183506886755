import React, { useEffect, useRef, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { QRCode } from 'antd'
import './ValetCoupon.css'

const ValetCoupon = ({data, clubDetails}) => {
    const api = useHttp();
    return (

        <div className='pb-10 text-center'>
            <div className='p-5'>
                <QRCode value={JSON.stringify({
                    CarQrId: data?.uuid?.toString(),
                })} className='mx-auto valetCouponCode' style={{height: "60px", width: '60px'}} bgColor="#fff" />
            </div>
            <div className='venueLogo'>
                    <img src={clubDetails?.image} />  
                <p className=' font-semibold  text-4xl'>
                    {clubDetails?.name}
                </p>
            </div>
            <div className='venueLogo2'>
                    <img src={clubDetails?.image} />  
                <p className=' font-semibold  text-4xl'>
                    {clubDetails?.name}
                </p>
            </div>
            <div className='couponNumber1'>
                <p className=' font-semibold  text-4xl'>
                    {data?.id?.toString()}
                </p>
            </div>
            <div className='couponNumber2'>
                <p className=' font-semibold '>
                    {data?.id?.toString()}
                </p>
            </div>
        </div>
    )
}

export default ValetCoupon