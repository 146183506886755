import React, { useEffect, useState } from 'react'
import { Card, Col, Image, Row } from "antd";
import imageSrc from "../../../asset/image/image 2.png";
import PageComponent from "../../../component/common/Page-Component";
import { useNavigate, useParams } from 'react-router-dom';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import useHttp from '../../../hooks/use-http';
import moment from 'moment';
import { managerChatHistoryExportData } from '../../../util/exportData';

const MangerChatHistory = () => {
  // const [allManagers, setAllManagers] = useState([]);
  const [managerDetail, setManagerDetail] = useState(null);
  const { id } = useParams()

  const api = useHttp();
  const navigate = useNavigate()
  const chatHistory = (chatId) => {
    navigate(`/app/staff/manager/${id}/${chatId}`);
  }


  useEffect(() => {
    const GET_ALL_MANAGERS = { ...CONSTANTS.API.getManagers }
    api.sendRequest(GET_ALL_MANAGERS, (res) => {
      const manager = (res?.data?.managers?.filter(ele => ele?.id === +id))
      setManagerDetail({ details: manager?.map(data => data) })
    })
  }, [])

  return (
    <>
      <div>
        <Row gutter={[16, 16]} className="mt-5">
          <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card size="small" className="p-5">
              <Row>
                <Col>
                  <Image
                    width={80}
                    height={80}
                    className="rounded-full object-fill"
                    preview
                    src={managerDetail?.details[0]?.image || imageSrc}
                    alt="Images is Not Availabel"
                  />
                </Col>
                <Col>
                  <p className="text-2xl pt-5 pl-3  font-medium"> {managerDetail?.details[0]?.name}</p>

                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="mt-5 ">
        <PageComponent
          exportCSV
          tableTitle="Chat History"
          tableHeaders="MANAGER_CHAT_HISTORY"

          getAPI={CONSTANTS.API.getChatHistoryList}
          extraEndPointData={`/${id}`}
          extraResData='chatRecords'
          getData={(res) => {
            // console.log(res);
            return res?.map(data => {
              if (data?.ManagerChats?.length !== 0) {
                return {
                  ...data,
                  no: `CH${data?.id.toString().padStart(7, "0")}`,
                  name: data?.ManagerChats[0]?.Manager1?.name || data?.ManagerChats[0]?.Manager2?.name,
                  image: data?.ManagerChats[0]?.Manager1?.profilePic || data?.ManagerChats[0]?.Manager2?.profilePic,
                  type: 'Manager Chats',
                  date: `${moment.utc(data?.lastMessageOn).local()?.format('DD MMM,')}${moment.utc(data?.lastMessageOn).local()?.format('hh:mm A')}`,
                }
              }
              if (data?.UserManagerChats?.length !== 0) {
                return {
                  ...data,
                  no: `CH${data?.id.toString().padStart(7, "0")}`,
                  name: data?.UserManagerChats[0]?.User?.name,
                  image: data?.UserManagerChats[0]?.User?.profilePic,
                  type: 'User Manager Chats',
                  date: `${moment.utc(data?.lastMessageOn).local()?.format('DD MMM,')}${moment.utc(data?.lastMessageOn).local()?.format('hh:mm A')}`,
                }
              }
            });
          }}
          viewData
          viewFunction={chatHistory}
          exportData={managerChatHistoryExportData}
        />
      </div>
    </>
  );
}

export default MangerChatHistory;