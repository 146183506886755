import React, { useEffect, useState } from 'react'
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import useHttp from '../../../hooks/use-http';
import { foodExportData } from '../../../util/exportData';

const FoodList = () => {
  const api = useHttp();
  const [foodCategories, setFoodCategories] = useState([]);
  // console.log(foodCategories);

  CONSTANTS.FORM_FIELD.FOOD_LIST_MODAL[1].option = foodCategories
  CONSTANTS.FORM_FIELD.EDIT_FOOD_LIST_MODAL[1].option = foodCategories


  useEffect(() => {
    const FOOD_CATEGORY_API = { ...CONSTANTS.API.getFoodCategory }
    api.sendRequest(FOOD_CATEGORY_API, (res) => {
      // console.log(res?.data?.foodCategories);
      setFoodCategories(res?.data?.foodCategories?.map(data => {
        return {
          id: data?.id,
          value: data?.id,
          Label: data?.category,
        };
      }));
    })
  }, [])
  return (
    <div>
      <PageComponent
        exportCSV
        tableHeaders="FOOD_LIST"
        tableTitle="Food List"
        getAPI={CONSTANTS.API.getFoodList}
        extraResData="foodItems"
        getData={(res) => {
          return res?.map((data) => {
            // console.log(res);
            return {
              ...data,
              no: `FI${data?.id.toString().padStart(7, "0")}`,
              priceD: `₹${data?.price || 0}`,
              price: +data?.price,
            };
          });
        }}
        formData
        modalFields="FOOD_LIST_MODAL"
        modalButton="Add New Food"
        addModalTitle="Add New Food"
        addAPI={CONSTANTS.API.addFoodList}
        editformData
        editModalFields="EDIT_FOOD_LIST_MODAL"
        editModalTitle='Edit Food Data'
        editAPI={CONSTANTS.API.editFoodList}
        deleteData
        deleteAPI={CONSTANTS.API.deleteFoodList}
        exportData={foodExportData}
      />
    </div>
  );
}

export default FoodList