import React, { useState } from 'react'
import { Col, Image, Modal, Row } from 'antd'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { postExportData } from '../../../util/exportData';
import PageComponent from '../../../component/common/Page-Component';

const Report = () => {
    const [postData, setPostData] = useState(null)
    return (
        <>
            <div className="mt-5 mx-3">
                <PageComponent
                    exportCSV
                    tableHeaders="POST_REPORT"
                    tableTitle="Post Report"
                    getAPI={CONSTANTS.API.getReports}
                    extraResData="reports"
                    getData={(res) => {
                        return res?.map(data => {
                            return {
                                ...data,
                                no: `PR${data?.id.toString().padStart(7, "0")}`,
                                name: data?.Post?.Club?.name,
                                userName: data?.User?.name,
                                image: data?.Post?.mediaType === 'Image' ? data?.Post?.file : data?.Post?.thumbnail,
                                viewData: {
                                    id: data?.id,
                                    onClick: () => {
                                        setPostData(data)
                                    }
                                }

                            }
                        })
                    }}
                    exportData={postExportData}
                    datefilter
                />
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={postData !== null}
                onOk={() => setPostData(null)}
                onCancel={() => setPostData(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <div className="flex">
                    <Image
                        className="rounded-full"
                        src={postData?.Post?.mediaType === 'Image' ? postData?.Post?.file : postData?.Post?.thumbnail}
                        preview
                        width={50}
                        height={50}
                    />
                    <h2 className="mt-1 pl-3">{postData?.Post?.Club?.name}</h2>
                </div>
                <section className="mt-5">
                    <p className="text-slate-500 font-medium">{postData?.reasion}</p>

                </section>
                <section>
                    <h3>Reported By</h3>
                    {/* Headers */}

                    <Row>
                        <Col span={4}>
                            <Image
                                className="rounded-full"
                                src={postData?.User?.profilePic}
                                preview
                                width={40}
                                height={40}
                            />
                        </Col>
                        <Col span={8}>
                            <p className="text-xl mt-2">{postData?.User?.name}</p>
                        </Col>


                    </Row>
                    <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />

                </section>
            </Modal>
        </>
    )
}

export default Report