import React from "react";
import { Col, Image, Row } from "antd";
import photo from "../../../asset/image/clubs.png"
import contact from "../../../asset/image/dummy-avatar.jpg"
import doc from "../../../asset/image/docs.svg"
import { useParams } from "react-router-dom";

const SenderChat = ({
    message = "",
    time = "",
    type = 'no data found'

}) => {

    const { userId } = useParams()
    const invitaion = {
        msg: '',
        status: null,
    }

    if (type === 'RSVP' || type === 'Booking' || type === 'Post') {
        message = JSON.parse(message)
        // if (type === 'Post')
        // {
        //     console.log(message);
        //    }
        if (type === 'RSVP') {
            const rsvpsMsg = message?.rsvpInvitation.filter(ele => +ele?.userId === +userId)


            if (!rsvpsMsg[0]?.accept && !rsvpsMsg[0]?.reject) {
                invitaion.msg = 'Pending Event'
                invitaion.status = 0
            }

            else if (rsvpsMsg[0]?.accept) {

                invitaion.msg = 'You accepted the invitation'
                invitaion.status = 1

            }
            else {
                invitaion.msg = 'You declined the invitation'
                invitaion.status = -1
            }
        }

    }

    // Download Document
    const downloadDoc = () => {
        fetch(message) // Replace 'data.message' with your actual API response field
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${message.split('/')[3]?.trim()}`; // Replace 'file.pdf' with the desired filename
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    }




    return (
        <>
            {type === 'Text' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4 rounded-lg max-w-lg"
            >
                <p className="text-sm text-left mb-0 pt-3 pb-1">{message}</p>
                <p className="text-slate-600 font-bold -mt-1 text-sm float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}

            {type === 'Img' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-2  rounded-lg"
            >
                <p className="mt-2 mb-0">
                    <Image src={message || photo} alt="image is not avilable" height={100} width={100} preview className="block rounded-md" />
                </p>
                <p className="text-slate-600 font-bold -mt-1 mb-0 float-left" style={{ fontSize: '10px' }} >{time}</p>
            </div>}

            {type === 'Contact' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-3 py-2 rounded-lg"
            >
                <Row >
                    <Col>
                        <Image src={contact} height={40} preview width={40} alt="contact" />
                    </Col>
                    <Col >
                        <div className="mx-2">
                            <p className="text-sm">
                                {message.split(':')[0]?.trim()}
                            </p>
                            <p className="-mt-4 text-left" style={{ fontSize: '12px' }}>
                                {message.split(':')[1]?.trim()}
                            </p>
                        </div>
                    </Col>
                </Row>
                <p className="text-slate-600  font-bold -mt-1.5 -mb-1  float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}
            {type === 'Doc' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="cursor-pointer float-right px-4 py-2 rounded-lg"
                onClick={downloadDoc}
            >
                <Row >
                    <Col>
                        <Image src={doc} preview height={40} width={40} alt="document" />
                    </Col>
                    <Col >
                        <div className="mx-2 mt-2">
                            <p className="text-sm">
                                {message.split('/')[3]?.trim()}
                            </p>

                        </div>
                    </Col>
                </Row>
                <p className="text-slate-600  font-bold mt-1 -mb-1  float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}
            {type === 'RSVP' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>
                    <p className="p-0 m-0 font-semibold text-left pb-3 text-base">
                        {message?.rsvpSenderName} is inviting you to,
                    </p>
                    <div className="py-4 px-3" style={{ borderRadius: '8px', border: '1px solid rgba(255, 255, 255, 0.05)' }}>
                        {/* Club Details */}
                        <div className="my-4">
                            <Row className="py-1" style={{
                                borderRadius: '16px',
                                background: 'rgba(255, 255, 255, 0.05)'


                            }}>
                                <Col span={6}>
                                    <Image src={message?.clubImage} alt='club' width={50} height={50} className="rounded-full" />
                                </Col>
                                <Col className="" span={10}>
                                    <p className="m-0 pt-3 text-base">
                                        {message?.clubName}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        {/* Event Details */}
                        <div>
                            <Row className="">
                                <Col span={7}>
                                    <Image src={message?.eventImage} alt='event' width={80} height={80} />
                                </Col>
                                <Col span={17} style={{ background: '#3F1200' }} >
                                    <div>
                                        <p className="p-0 m-0 ml-2 pt-3 text-lg">
                                            {message?.eventName}
                                        </p>
                                        <p className="text-slate-400 ml-2 p-0 m-0">
                                            {message?.StartDate
                                            }
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {invitaion.status === 1 && <div className="my-2" style={{ background: '#76EE59', borderRadius: '8px' }} >

                            <div className="text-black font-semibold py-2 px-4">
                                You accepted the invitation
                            </div>

                        </div>}
                        {invitaion.status === -1 && <div className="my-2" style={{ background: '#EF1A0C', borderRadius: '8px' }} >


                            <div className="text-black font-semibold py-2 px-4">
                                You declined the invitation
                            </div>

                        </div>}
                        {invitaion.status === 0 && <div className="my-2 bg-gray-300" style={{ borderRadius: '8px' }} >


                            <div className="text-black font-semibold py-2 px-4">
                                Your invitation is Pending
                            </div>

                        </div>}

                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
            {type === 'Booking' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>

                    <p className="p-0 m-0 font-semibold text-left pb-3 text-base">
                        {message?.TicketSenderName} booked a event for you, Check the tickets.,
                    </p>
                    <div className="py-4 px-3" style={{ borderRadius: '8px' }}>

                        {/* Event Details */}
                        <div>
                            <Row className="">
                                <Col span={7}>
                                    <Image src={message?.eventImage} alt='club' className="rounded-lg" width={80} height={100} />
                                </Col>
                                <Col span={17}  >
                                    <div>
                                        <p className="p-0 m-0 pt-3 ml-5 text-left text-lg">
                                            {message?.eventName}
                                        </p>
                                        <p className="text-white text-opacity-50 p-0 m-0 ml-5 font-semibold text-left">
                                            {message?.StartDate}
                                        </p>
                                        <Row className="ml-3 pt-2" >
                                            <Col span={6}>
                                                <Image src={message?.clubImage} alt='club' style={{ border: '1px solid #737373' }} width={30} height={30} className="rounded-full" />
                                            </Col>
                                            <Col className="" span={14}>
                                                <p className="m-0 pt-1.5 text-sm text-white text-opacity-50">
                                                    {message?.clubName}
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
            {type === 'Post' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>


                    <div className="py-4 px-3">

                        {/* Event Details */}
                        <div className="flex justify-center">
                            <Image src={message?.postImage} className="rounded-lg" width={260} height={200} alt="post" />
                        </div>
                        {/* {console.log(message?.Descriptipn.split(""))} */}
                        <div className=" text-sm text-white text-opacity-80 text-left">
                            {message?.Descriptipn}
                        </div>


                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
        </>
    );
};

export default SenderChat;
