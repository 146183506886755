import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import { rejectedEventExportData } from '../../../../util/exportData';

const RejectedEvents = () => {
    return (
        <>
            <div>
                <PageComponent
                    exportCSV
                    tableHeaders="REJECTED_EVENTS"
                    tableTitle="Rejected Events List"
                    getAPI={CONSTANTS.API.getRejectedEvents}
                    extraResData="events"
                    getData={(res) => {
                        // console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `E${data?.id.toString().padStart(7, "0")}`,
                                date: moment
                                    .utc(data?.startDate, "YYYY-MM-DD")
                                    .local()
                                    .format("DD MMM, YYYY"),
                                time: moment
                                    .utc(data?.startDate, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm A"),
                                userName: data?.User?.name,
                            };
                        });
                    }}

                    exportData={rejectedEventExportData}

                />

            </div>
        </>
    )
}

export default RejectedEvents