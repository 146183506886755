import Eye from "../../asset/image/eye.png";
import { Button, Image, Input, Popconfirm, Select, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import moment from "moment/moment";
import { CLUB_ID } from "../../page/app/club-profile";

export let MOBILE_TOKEN = {
  token: "",
};

const clubId = localStorage.getItem("clubId");
// const clubId = CLUB_ID;
// console.log(clubId);
const todayDate = moment().format('YYYY-MM-DD')
// console.log(todayDate);

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};
const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <Button
      type="primary"
      onClick={() => {
        window.open(value, "Download");
      }}
    >
      <DownloadOutlined />
    </Button>
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined

      />
    </Button>
  );
};

// Render Images
const ImageRanders = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";


const CONSTANTS = {
  BASE_URL: "http://20.244.39.211/api/v1",
  GETMe: null,
  TABLE: {
    USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Referral Code",
        dataIndex: "code",
        sorter: (a, b) => alphanumericSort(a, b, "code"),
      },
      {
        title: "Username",
        dataIndex: "user",
        sorter: (a, b) => alphanumericSort(a, b, "user"),
      },
      {
        title: "Date of birth",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Private account",
        dataIndex: "account",
        render: RenderCheckbox,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      // {
      //   title: "Block User",
      //   dataIndex: "toggle",
      //   render: RenderDeleteButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Block User",
        dataIndex: "toggle",
        render: RenderToggleButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton
      },
    ],
    MANAGER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },

      {
        title: "Block Manager",
        dataIndex: "toggle",
        render: RenderToggleButton,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    MANAGER_CHAT_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },


      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Chat View",
        dataIndex: "view",
        render: RenderViewButton,
      },
    ],
    BOUNCERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },

      {
        title: "Block Bouncer",
        dataIndex: "toggle",
        render: RenderToggleButton,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    VALETS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },

      {
        title: "Block Valet",
        dataIndex: "toggle",
        render: RenderToggleButton,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    USERS_BOOKING: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      ,
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
      },
      {
        title: "Club Name",
        dataIndex: "club",
        sorter: (a, b) => alphanumericSort(a, b, "club"),
      },
      {
        title: "Event Name",
        dataIndex: "event",
        sorter: (a, b) => alphanumericSort(a, b, "event"),
      },
      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Entries",
        dataIndex: "entries",
        sorter: (a, b) => alphanumericSort(a, b, "entries"),
      },
      {
        title: "Total Amount",
        dataIndex: "total",
        sorter: (a, b) => alphanumericSort(a, b, "total"),
      },
      {
        title: "Address",
        dataIndex: "address",
        sorter: (a, b) => alphanumericSort(a, b, "address"),
      },
      {
        title: "Invoice",
        dataIndex: "invoice",
        render: RenderDownloadButton,
      },
    ],
    USERS_CAR_VALET: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      ,
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
      },
      {
        title: "Car Company",
        dataIndex: "company",
        sorter: (a, b) => alphanumericSort(a, b, "company"),
      },
      {
        title: "Car Model",
        dataIndex: "model",
        sorter: (a, b) => alphanumericSort(a, b, "model"),
      },
      {
        title: "Car Number",
        dataIndex: "number",
        sorter: (a, b) => alphanumericSort(a, b, "number"),
      },
    ],
    USERS_CHAT_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },

      {
        title: "Chat View",
        dataIndex: "view",
        render: RenderViewButton,
      },
    ],
    USERS_POST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Likes",
        dataIndex: "likes",
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        sorter: (a, b) => alphanumericSort(a, b, "comment"),
      },
      {
        title: "Caption",
        dataIndex: "caption",
        sorter: (a, b) => alphanumericSort(a, b, "caption"),
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],

    TODAY_EVENT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Start Time",
        dataIndex: "startTime",
        sorter: (a, b) => alphanumericSort(a, b, "startTime"),
        width: 125,
      },
      {
        title: "End Time",
        dataIndex: "endTime",
        sorter: (a, b) => alphanumericSort(a, b, "endTime"),
        // width
      },

      {
        title: "Artist",
        dataIndex: "artistTags",
        sorter: (a, b) => alphanumericSort(a, b, "artistTags"),
      },
    ],
    MY_EVENTS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Start Date & Time",
        dataIndex: "startDateWithTime",
        sorter: (a, b) => alphanumericSort(a, b, "startDateWithTime"),
        width: 200,
      },
      {
        title: "End Date & Time",
        dataIndex: "endDateWithTime",
        sorter: (a, b) => alphanumericSort(a, b, "endDateWithTime"),
        width: 200,
      },

      {
        title: "Artist",
        dataIndex: "artistTags",
        sorter: (a, b) => alphanumericSort(a, b, "artistTags"),
        width: 130,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 60,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 100,
      },
    ],

    REQUEST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 90,

      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 150,
      },
      {
        title: "Artist",
        dataIndex: "artistTags",
        sorter: (a, b) => alphanumericSort(a, b, "artistTags"),
        width: 130,
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 130,
      },
      {
        title: "Time",
        dataIndex: "time",
        sorter: (a, b) => alphanumericSort(a, b, "time"),
        width: 120,
      },

      // {
      //   title: "Attendees",
      //   dataIndex: "attendeesLimit",
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 140,
      // },

      {
        title: "Action",
        dataIndex: "action",
        render: RenderAcceptRejectButton,
        width: 200,
      },
    ],

    EVENTS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Time",
        dataIndex: "time",
        sorter: (a, b) => alphanumericSort(a, b, "time"),
      },

      {
        title: "Fees",
        dataIndex: "fees",
        sorter: (a, b) => alphanumericSort(a, b, "fees"),
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        sorter: (a, b) => alphanumericSort(a, b, "revenue"),
      },
    ],
    REJECTED_EVENTS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "Time",
        dataIndex: "time",
        sorter: (a, b) => alphanumericSort(a, b, "time"),
      },


    ],
    TODAY_EVENTS_USER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
      },

      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
    ],
    APPROVED_EVENTS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "User Name",
        dataIndex: "userName",
        // sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 150,
      },
      {
        title: "Date",
        dataIndex: "date",
        // sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 120,
      },
      {
        title: "Time",
        dataIndex: "time",
        // sorter: (a, b) => alphanumericSort(a, b, "time"),
        width: 100,
      },
      {
        title: "Age Limit",
        dataIndex: "ageRestriction",
        sorter: (a, b) => alphanumericSort(a, b, "ageRestriction"),
        width: 130,
      },
      {
        title: "Fees",
        dataIndex: "fees",
        sorter: (a, b) => alphanumericSort(a, b, "fees"),
        width: 70,
      },
      {
        title: "Details",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        width: 250,
      },
    ],
    VISITS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "User",
        dataIndex: "userName",
        // sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 120,
      },
      {
        title: "Date & Time",
        dataIndex: "dateTime",
        // sorter: (a, b) => alphanumericSort(a, b, "dateTime"),
        width: 150,
      },
      {
        title: "Likes",
        dataIndex: "likeCount",
        sorter: (a, b) => alphanumericSort(a, b, "likeCount"),
        width: 70,
      },
      {
        title: "Comment",
        dataIndex: "commentCount",
        sorter: (a, b) => alphanumericSort(a, b, "commentCount"),
        width: 100,
      },
      {
        title: "Caption",
        dataIndex: "caption",
        // sorter: (a, b) => alphanumericSort(a, b, "caption"),
        width: 200,
      },
    ],
    FOOD_LIST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 60,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Price",
        dataIndex: "priceD",
        // sorter: (a, b) => alphanumericSort(a, b, "priceD"),
        width: 70,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        width: 200,
      },
      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 70,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    FOOD_CATEGORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Name",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
    ],
    BANNER_LIST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Banner",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Dimensions",
        dataIndex: "dimensions",
        sorter: (a, b) => alphanumericSort(a, b, "dimensions"),
        width: 80,
      },
      {
        title: "Navigation Link",
        dataIndex: "link",
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        width: 280,
      },


      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 40,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 60,
      },
    ],
    POST_REPORT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },
      {
        title: "View",
        dataIndex: "viewData",
        render: RenderViewButton,
        width: 60,
      },

      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 100,
      },
      {
        title: "Reported Post",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
    ]
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "mobile",
        id: "mobile",
        type: "tel",
        placeholder: "Enter Mobile Number",
        required: true,
        width: 24,
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: " ",
        required: true,
      },
      {
        no: 2,
        Label: "Username",
        name: "user",
        id: "user",
        type: "text",
        placeholder: " ",
        required: true,
      },

      {
        no: 3,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "tel",
        placeholder: " ",
        required: true,
        width: 24,
      },

      {
        no: 4,
        Label: "Date of Birth",
        name: "date",
        id: "date",
        type: "date",
        placeholder: " ",
        required: true,
      },
      {
        no: 5,
        Label: "Profile Picture",
        name: "profilePic",
        id: "profilePic",
        type: "file",
        placeholder: " ",
        required: true,
        width: 24,
      },
    ],

    MANAGER_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Manger Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: true,
      },
    ],
    EDIT_MANAGER_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Manger Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],
    BOUNCERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Boncer Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: true,
      },
    ],
    EDIT_BOUNCERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Boncer Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],

    VALETS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Valet Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: true,
      },
    ],
    EDIT_VALETS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Valet Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],

    MY_EVENTS_MODAL: [
      {
        no: 1,
        Label: "Event Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Event Name",
        required: true,
        width: 24,
      },

      {
        no: 2,
        Label: "Event Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Event Details",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Tag Artist (optional)",
        name: "artistTags",
        id: "artistTags",
        type: "text",
        placeholder: "Enter Artist Name",
        required: false,
        width: 24,
      },
      {
        no: 4,
        Label: "Age Limit",
        name: "ageRestriction",
        id: "ageRestriction",
        type: "number",
        placeholder: "Enter Age Limit",
        required: true,
        width: 12,
      },
      {
        no: 5,
        Label: "Start Date & Time",
        name: "startDate",
        id: "startDate",
        type: "date",
        format: "DD/MM/YYYY hh:mm A",
        showTime: "hh:mm",
        placeholder: "Enter Start Date & Time",
        required: true,
        width: 12,
      },
      {
        no: 6,
        Label: "End Date & Time",
        name: "endDate",
        id: "endDate",
        type: "date",
        format: "DD/MM/YYYY hh:mm A",
        showTime: "hh:mm",
        placeholder: "Enter End Date & Time",
        required: true,
        width: 12,
      },

      // {
      //   no: 9,
      //   Label: "Duration (approx.)",
      //   name: "duration",
      //   id: "duration",
      //   type: "number",
      //   placeholder: "Enter Duration",
      //   required: false,
      //   width: 12,
      // },

      {
        no: 10,
        Label: "Entry fee per person",
        name: "fees",
        id: "fees",
        placeholder: "Enter fee per person",
        type: "entryfee",
        required: false,
      },
      {
        no: 11,
        Label: "Event Image",
        name: "image",
        id: "image",
        type: "file",
        required: true,
      },
    ],
    EDIT_MY_EVENTS_MODAL: [
      {
        no: 1,
        Label: "Event Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Event Name",
        required: true,
        width: 24,
      },

      {
        no: 2,
        Label: "Event Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Event Details",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Tag Artist (optional)",
        name: "artistTags",
        id: "artistTags",
        type: "text",
        placeholder: "Enter Artist Name",
        required: false,
        width: 24,
      },
      {
        no: 4,
        Label: "Age Limit",
        name: "ageRestriction",
        id: "ageRestriction",
        type: "number",
        placeholder: "Enter Age Limit",
        required: true,
        width: 12,
      },
      {
        no: 5,
        Label: "Start Date & Time",
        name: "startDate",
        id: "startDate",
        type: "date",
        format: "DD/MM/YYYY hh:mm A",
        showTime: "hh:mm",
        placeholder: "Enter Start Date",
        required: true,
        width: 12,
      },
      {
        no: 6,
        Label: "End Date & Time",
        name: "endDate",
        id: "endDate",
        type: "date",
        format: "DD/MM/YYYY hh:mm A",
        showTime: "hh:mm",
        placeholder: "Enter End Date",
        required: true,
        width: 12,
      },

      // {
      //   no: 9,
      //   Label: "Duration (approx.)",
      //   name: "duration",
      //   id: "duration",
      //   type: "number",
      //   placeholder: "Enter Duration",
      //   required: false,
      //   width: 12,
      // },

      {
        no: 10,
        Label: "Entry fee per person",
        name: "fees",
        id: "fees",
        placeholder: "Enter fee per person",
        type: "entryfee",
        required: false,
      },
      {
        no: 11,
        Label: "Event Image",
        name: "image",
        id: "image",
        type: "file",
        required: false,
      },
    ],
    UPDATE_BANK_DETAILS_MODAL: [
      {
        no: 1,
        Label: "Account Holder Name",
        name: "bancAccHolderName",
        id: "bancAccHolderName",
        type: "text",
        placeholder: "Enter Account Holder Name",
        required: true,
      },
      {
        no: 2,
        Label: "Account Number",
        name: "bancAccNo",
        id: "bancAccNo",
        type: "text",
        placeholder: "Enter Account Number",
        required: true,
      },
      {
        no: 3,
        Label: "PAN Number",
        name: "PAN",
        id: "PAN",
        type: "text",
        placeholder: "Enter PAN Number",
        required: true,
      },
      {
        no: 4,
        Label: "Branch Name",
        name: "bancBranch",
        id: "bancBranch",
        type: "text",
        placeholder: "Enter Branch Name",
        required: true,
      },
      {
        no: 5,
        Label: "IFSC Code",
        name: "IFSC",
        id: "IFSC",
        type: "text",
        placeholder: "Enter IFSC Code",
        required: true,
      },
      {
        no: 6,
        Label: "GST Number",
        name: "GST",
        id: "GST",
        type: "text",
        placeholder: "Enter GST Number",
        required: true,
        disabled: true,
      },
    ],
    FOOD_LIST_MODAL: [
      {
        no: 1,
        Label: "Food Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Food Name",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Category",
        name: "FoodCategoryId",
        id: "FoodCategoryId",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        width: 24,
      },
      {
        no: 3,
        Label: "Price",
        name: "price",
        id: "price",
        type: "number",
        placeholder: "Enter Price",
        required: true,
        width: 24,
      },
      {
        no: 4,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Description",
        required: true,
        width: 24,
      },
      {
        no: 5,
        Label: "Veg",
        name: "isVeg",
        id: "isVeg",
        type: "switch",
        placeholder: "",
        required: true,
        width: 24,
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: true,
        width: 24,
      },
    ],
    EDIT_FOOD_LIST_MODAL: [
      {
        no: 1,
        Label: "Food Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Food Name",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Category",
        name: "FoodCategoryId",
        id: "FoodCategoryId",
        type: "select",
        placeholder: " ",
        required: true,
        option: [],
        width: 24,
      },
      {
        no: 3,
        Label: "Price",
        name: "price",
        id: "price",
        type: "number",
        placeholder: "Enter Price",
        required: true,
        width: 24,
      },
      {
        no: 4,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Description",
        required: true,
        width: 24,
      },
      {
        no: 5,
        Label: "Veg",
        name: "isVeg",
        id: "isVeg",
        type: "switch",
        placeholder: "",
        required: true,
        width: 24,
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
        width: 24,
      },
    ],
    POST_UPLOAD_MODAL: [

      {
        no: 1,
        Label: "Post",
        name: "file",
        id: "file",
        type: "post",
        placeholder: "",
        required: true,
        width: 24,
      },
      // {
      //   no: 2,
      //   Label: "Thumbnail",
      //   name: "thumbnail",
      //   id: "thumbnail",
      //   type: "file",
      //   placeholder: "",
      //   required: true,
      //   width: 24,
      // },
      {
        no: 3,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Write s description...",
        required: false,
        width: 24,
      },



    ],
    EDIT_POST_UPLOAD_MODAL: [

      {
        no: 1,
        Label: "Post",
        name: "file",
        id: "file",
        type: "post",
        placeholder: "",
        required: false,
        width: 24,
      },
      {
        no: 3,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Write s description...",
        required: false,
        width: 24,
      },



    ],
    FOOD_CATEGORY_MODAL: [
      {
        no: 5,
        Label: "Veg",
        name: "veg",
        id: "veg",
        type: "switch",
        placeholder: "",
        required: true,
        width: 24,
      },
    ],
    QRCODE_MODAL: [
      {
        no: 5,
        Label: "QRCode Count",
        name: "qrCount",
        id: "qrCount",
        type: "text",
        placeholder: "Enter QRCode Count",
        required: true,
        width: 24,
      },
    ],
    NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Followers",
        name: "follwersNotification",
        id: "follwersNotification",
        type: "switch",
        placeholder: "",
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Likes",
        name: "likesNotification",
        id: "likesNotification",
        type: "switch",
        placeholder: "",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Comments",
        name: "commentsNotification",
        id: "commentsNotification",
        type: "switch",
        placeholder: "",
        required: true,
        width: 24,
      },
    ],
    BANNER_MODAL: [
      {
        no: 1,
        Label: "Banner",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Navigation Link",
        name: "link",
        id: "link",
        type: "text",
        placeholder: "Enter Navigation Link with http or https",
        required: false,
        width: 24,
      },
      // {
      //   no: 3,
      //   Label: "Dimensions",
      //   name: "dimensions",
      //   id: "dimensions",
      //   type: "select",
      //   placeholder: 'Select a Dimensions',
      // option: [
      //   {
      //     id: 1,
      //     Label: '304 X 103 px',
      //     value: '304 X 103',
      //   },
      //   {
      //     id: 2,
      //     Label: '142 X 122 px',
      //     value: '142 X 122',
      //   },
      //   {
      //     id: 3,
      //     Label: '304 X 171 px',
      //     value: '304 X 171',
      //   },
      // ],
      //   required: true,
      //   width: 24,
      // },
    ],
    EDIT_BANNER_MODAL: [
      {
        no: 1,
        Label: "Banner",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
        width: 24,
      },

      // {
      //   no: 2,
      //   Label: "Dimensions",
      //   name: "dimensions",
      //   id: "dimensions",
      //   type: "select",
      //   placeholder: "Select a Dimensions",
      //   option: [
      //     {
      //       id: 1,
      //       Label: '304 X 102 px',
      //       value: '304 X 102',
      //     },
      //     {
      //       id: 2,
      //       Label: '142 X 122 px',
      //       value: '142 X 122',
      //     },
      //     {
      //       id: 3,
      //       Label: '304 X 171 px',
      //       value: '304 X 171',
      //     },
      //   ],
      //   required: true,
      //   width: 24,
      // },
      {
        no: 3,
        Label: "Navigation Link",
        name: "link",
        id: "link",
        type: "text",
        placeholder: "Enter Navigation Link with http or https",
        required: false,
        width: 24,
      },
    ],
  },

  API: {
    // Login API
    sendOTP: {
      type: "POST",
      endpoint: "/clubs/verify/mobile",
    },
    verifyOTP: {
      type: "POST",
      endpoint: "/clubs/verify/OTP",
    },

    // Update Mobile Number API
    sendUpdateOTP: {
      type: "PATCH",
      endpoint: "/clubs/verify/mobile",
    },
    verifyUpdateOTP: {
      type: "PATCH",
      endpoint: "/clubs/verify/OTP",
    },

    // Club Analytics API
    getClubAnalytics: {
      type: "GET",
      endpoint: "/clubs/analytics",
    },
    // Club Followers API
    getClubFollowers: {
      type: "GET",
      endpoint: `/club-followers/?ClubId=${clubId}`,
    },

    // Notification API
    setNotification: {
      type: "PATCH",
      endpoint: '/clubs/notifications/config',
    },

    // Club Post API
    getClubPost: {
      type: "GET",
      endpoint: "/posts/clubs",
    },
    addClubPost: {
      type: "POST",
      endpoint: "/posts",
    },
    editClubPost: {
      type: "PATCH",
      endpoint: "/posts/id/:dataId",
    },
    deleteClubPost: {
      type: "DELETE",
      endpoint: "/posts/id/:dataId",
    },

    // Club Profile API
    getMyProfile: {
      type: "GET",
      endpoint: "/clubs/profile",
    },
    editMyProfile: {
      type: "PATCH",
      endpoint: "/clubs/profile",
    },

    // Manger Navigation API
    getOneManager: {
      type: "GET",
      endpoint: "/managers/id/:dataId",
    },
    getManagers: {
      type: "GET",
      endpoint: `/managers/?ClubId=${clubId}`,
    },
    addManagers: {
      type: "POST",
      endpoint: "/managers",
    },
    editManagers: {
      type: "PATCH",
      endpoint: "/managers/id/:dataId",
    },
    deleteManagers: {
      type: "DELETE",
      endpoint: "/managers/id/:dataId",
    },

    //  Chat API
    getChats: {
      type: "GET",
      endpoint: `/chat/records/messages`,
    },
    // Bouncers Navigation API
    getChatHistoryList: {
      type: "GET",
      endpoint: `/chat/records/managers`,
    },


    // Bouncers Navigation API
    getBouncers: {
      type: "GET",
      endpoint: `/bouncers/?ClubId=${clubId}`,
    },
    addBouncers: {
      type: "POST",
      endpoint: "/bouncers",
    },
    editBouncers: {
      type: "PATCH",
      endpoint: "/bouncers/id/:dataId",
    },
    deleteBouncers: {
      type: "DELETE",
      endpoint: "/bouncers/id/:dataId",
    },

    // Valets Navigation API
    getValets: {
      type: "GET",
      endpoint: `/valets/?ClubId=${clubId}`,
    },
    addValets: {
      type: "POST",
      endpoint: "/valets",
    },
    editValets: {
      type: "PATCH",
      endpoint: "/valets/id/:dataId",
    },
    deleteValets: {
      type: "DELETE",
      endpoint: "/valets/id/:dataId",
    },

    // My Events Navigation API
    getOneEvent: {
      type: "GET",
      endpoint: `/events/id/:dataId`,
    },

    // My Events -> Today Events API
    getTodayEvents: {
      type: "GET",
      endpoint: `/events?ClubId=${clubId}&status=1&date=${todayDate}`,
    },
    getMyEvents: {
      type: "GET",
      endpoint: `/events?ClubId=${clubId}&status=1`,
    },
    addMyEvents: {
      type: "POST",
      endpoint: "/events/",
    },
    deleteMyEvents: {
      type: "DELETE",
      endpoint: "/events/id/:dataId",
    },
    editMyEvents: {
      type: "PATCH",
      endpoint: "/events/id/:dataId",
    },

    // Today Events -> Users API
    getTodayEventsUsers: {
      type: "GET",
      endpoint: `/bookings/event/participants`,
    },

    // My Events -> Approved Events API
    getApproovedEvents: {
      type: "GET",
      endpoint: `/events/approved`,
    },
    // My Events -> Rejected Events API
    getRejectedEvents: {
      type: "GET",
      endpoint: `/events?ClubId=${clubId}&status=0`,
    },

    //  My Events -> Request Navigation API
    getRequest: {
      type: "GET",
      endpoint: `/events?ClubId=${clubId}&status=-1`,
    },
    acceptOrRejectRequest: {
      type: "PATCH",
      endpoint: "/events/approve/reject/:dataId",
    },

    //  My Events ->  ALL Events Navigation API
    getAllEvents: {
      type: "GET",
      endpoint: `/events?ClubId=${clubId}`,
    },

    //  Visits API
    getVisits: {
      type: "GET",
      endpoint: `/visitledgers/clubs?ClubId=${clubId}`,
    },

    //  RSVP API
    getRsvp: {
      type: "GET",
      endpoint: `rsvps/events/`,
    },

    //  Food List API
    getFoodList: {
      type: "GET",
      endpoint: `/foods/?ClubId=${clubId}`,
    },
    addFoodList: {
      type: "POST",
      endpoint: `/foods`,
    },
    editFoodList: {
      type: "PATCH",
      endpoint: `/foods/id/:dataId`,
    },
    deleteFoodList: {
      type: "DELETE",
      endpoint: `/foods/id/:dataId`,
    },
    //  Food Category API
    getFoodCategory: {
      type: "GET",
      endpoint: `/categories/food`,
    },

    //  Baner API
    getBanner: {
      type: "GET",
      endpoint: `/banners?ClubId=${clubId}`,
    },
    addBanner: {
      type: "POST",
      endpoint: `/banners`,
    },
    editBanner: {
      type: "PATCH",
      endpoint: `/banners/id/:dataId`,
    },
    deleteBanner: {
      type: "DELETE",
      endpoint: `/banners/id/:dataId`,
    },

    //  QRCode API
    generateQRcode: {
      type: "POST",
      endpoint: `/carQRs`,
    },
    getQRcode: {
      type: "GET",
      endpoint: `/carQRs`,
    },

    getReports: {
      type: "GET",
      endpoint: `/reports/posts/?ClubId=${clubId}`,
    },

    //  Notification List API
    getNotificationList: {
      type: "GET",
      endpoint: `/notifications/clubs?type=Club`,
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "Previous Bookings",
        name: "booking",
        id: "booking",
      },
      {
        no: 2,
        Label: "Car valet",
        name: "valet",
        id: "valet",
      },
      {
        no: 3,
        Label: "Chat History",
        name: "chat",
        id: "chat",
      },
      {
        no: 4,
        Label: "User Post",
        name: "post",
        id: "post",
      },
      {
        no: 5,
        Label: "About Info",
        name: "about",
        id: "about",
      },
    ],
    DASHBOARD: [
      {
        no: 1,
        Label: "Post",
        name: "post",
        id: "post",
      },

      // {
      //   no: 2,
      //   Label: "Events",
      //   name: "events",
      //   id: "events",
      // },
    ],
    QRCODE: [
      {
        no: 1,
        Label: "Generate QRCode",
        name: "generate",
        id: "generate",
      },

      {
        no: 2,
        Label: "QRCode List",
        name: "list",
        id: "list",
      },
    ]
  },
};
export default CONSTANTS;
