import React from 'react'
import {  Card, Tabs } from 'antd'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import GenerateQRcode from './tabs/generate-qr'
import QRList from './tabs/qr-list'

const QRCodeNavigation = () => {
  const menus =
  {
    generate: <GenerateQRcode />,
    list: <QRList />,
  };

  return (
    <>
      <Card className="my-5">
        <Tabs
          className="p-0 m-0"
          defaultActiveKey="1"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.QRCODE.map((ele) => {
            return {
              label: `${ele.Label}`,
              key: `${ele.id}`,
              children: menus[ele?.id],
            };
          })}
        />
      </Card>
    </>
  )
}

export default QRCodeNavigation