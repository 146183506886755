
export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthDetails = (accessToken) => {
  localStorage.setItem("token", accessToken);
};

export const deleteAuthDetails = () => localStorage.removeItem('token');
