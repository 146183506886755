import React, { useRef, useState } from 'react'
import { Button, Card, Checkbox, Form, Image, QRCode } from 'antd'
import ModalFormCreator from '../../../../component/common/ModalFormCreator'
import useHttp from '../../../../hooks/use-http'
import CONSTANTS from '../../../../util/constant/CONSTANTS'
import logo from '../../../../asset/logos/icon.svg'
import ReactToPrint from 'react-to-print';
import ValetCoupon from '../../../../component/common/ValetCoupon/ValetCoupon'
import './style.css'

const GenerateQRcode = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [qrCodeList, setQrCodeList] = useState([]);
    const [selectQRCode, setSelectQRCode] = useState({});
    const [clubDetails,setClubDetails] = useState({})
    const componentRef = useRef();
    const selectedRef = useRef();
    const api = useHttp();

    const count = Object.values(selectQRCode).reduce((count, value) => count + (value === true ? 1 : 0), 0);

    
    // Generate QRCode Data
    const genrateQRCode = (value) => {
        const payload = {
            qrCount: value?.qrCount
        }
        const GENERATE_QR_API = { ...CONSTANTS.API.generateQRcode };
        api.sendRequest(GENERATE_QR_API, (res) => {
            setQrCodeList(res?.data?.carQRs);
            setIsModalOpen(prev => !prev)
        }, payload)
        const CLUB_PROFILE_API = { ...CONSTANTS.API.getMyProfile };
        api.sendRequest(CLUB_PROFILE_API, (res) => {
          setClubDetails(res?.data?.club);
        });
    }
    // Download QR Code
    // const downloadQRCode = (id) => {
    //     const canvas = document.getElementById(`myqrcode${id}`)?.querySelector('canvas');
    //     if (canvas) {
    //         const url = canvas.toDataURL();
    //         const a = document.createElement('a');
    //         a.download = `${id}_QRCode.png`;
    //         a.href = url;
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //     }
    // };

    const selectedQR = (e) => {
        const id = e.target.id
        const payload = {}
        payload[id] = e.target.checked
        // console.log(payload);
        setSelectQRCode(prev => {
            // console.log(prev);

            return {
                ...prev,
                ...payload
            }


        })
    }

    return (
        <>
            <div className='my-5'>
                <Card>
                    <Button
                        onClick={() => {
                            setIsModalOpen((prev) => !prev);

                        }}
                        className="mt-5"
                        type="primary"
                    >
                        Generate QRCode
                    </Button>

                    {qrCodeList.length !== 0 && <ReactToPrint
                        trigger={() => <Button
                            onClick={() => {

                            }}
                            className="mt-5 mx-3"
                            type="primary"
                        >
                            Download All QRCode
                        </Button>}
                        content={() => componentRef.current}
                    />}

                    {Object.values(selectQRCode).includes(true) && <ReactToPrint
                        trigger={() => <Button
                            onClick={() => {

                            }}
                            className="mt-5 "
                            type="primary"
                        >
                            Download Selected {count}
                        </Button>}
                        content={() => selectedRef.current}
                    />}

                    <ModalFormCreator
                        open={isModalOpen}
                        onCreate={genrateQRCode}
                        onCancel={() => {
                            setIsModalOpen((prev) => !prev);
                        }}
                        name='Add QRCode Count '
                        menu='QRCODE_MODAL'
                    />


                    {/* Displayed QRCode */}
                    <div className='my-5' >

                        {qrCodeList?.map((data) => <div key={data?.id} className='inline-block m-2 couponContainer'>
                            <div className='valetCoupon rounded-lg'>
                                {/* <Checkbox className='ml-3 mt-2' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={(e) => e.preventDefault()} /> */}
                                <div class="punching"></div>
                                <ValetCoupon data={data} clubDetails={clubDetails}/>
                            </div>

                        </div>)}

                    </div>
                    {/* All Download QR */}
                    <div className='hidden'>
                        <div className='my-0' ref={componentRef}>

                            {qrCodeList?.map((data) => <div key={data?.id} className='inline-block my-2 mx-4 couponContainer'>
                                <div className='bg-black rounded-lg valetCoupon'>
                                    {/* <Checkbox className='ml-3 mt-0 mt-2' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={selectedQR} /> */}
                                    <div class="punching"></div>
                                    <ValetCoupon data={data} clubDetails={clubDetails}/>
                                </div>

                            </div>)}

                        </div>
                    </div>
                    {/* Selecetd QRCode */}
                    {/* <div className='hidden' >
                        <div className='mx-4 flex flex-wrap gap-x-2 gap-y-2 couponContainer' ref={selectedRef}>

                            {qrCodeList?.map((data) => <div key={data?.id} className=''>
                                {selectQRCode[`chk${data?.id.toString()}`] && <div className='bg-black w-40  rounded-lg valetCoupon'>
                                    <Checkbox className='ml-3 mt-0 hidden' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={selectedQR} />


                                    <div className='pb-10 text-center'>
                                        <div className='p-2'>
                                            <Image src={logo} alt='Bash Logo' preview style={{
                                                filter: 'grayscale(100%)'
                                            }} />
                                        </div>
                                        <div className='p-0'>
                                            <QRCode size={100} value={JSON.stringify({
                                                CarQrId: data?.uuid?.toString(),
                                            })} className='mx-auto' bgColor="#fff" />
                                        </div>
                                        <div className='bg-white my-3 h-7 rounded-lg mx-7' />

                                        <div className="my-4" style={{
                                            width: '100%',
                                            height: '2px',
                                            padding: '0px',
                                            backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' stroke=\'%23333\' stroke-width=\'5\' stroke-dasharray=\'6%2c 20\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")'
                                        }} />

                                        <div className='-mt-3'>
                                            <p className=' font-semibold  text-4xl' style={{
                                                color: 'white'
                                            }}>
                                                {data?.id?.toString()}
                                            </p>
                                        </div>
                                        <div className='bg-white -my-5 h-7 rounded-lg mx-7' />
                                    </div>

                                </div>}

                            </div>)}

                        </div>
                    </div> */}

                </Card>
            </div>
        </>
    )
}

export default GenerateQRcode